import { KIND, Tag, VARIANT } from 'baseui/tag'
import { humanizeStatus, colorReject, colorAccept, colorCancelled, colorCompleted } from '../core/utils'
import React from 'react'

function statusToTagProps(status) {
	switch (status) {
		case 'new':
			return { kind: KIND.primary, variant: VARIANT.solid, color: '#b0b5ba' }
		case 'aligning':
			return { kind: KIND.primary, variant: VARIANT.solid, color: colorAccept }
		case 'in_progress':
			return { kind: KIND.positive, variant: VARIANT.solid, color: colorAccept }
		case 'completed':
			// return { kind: KIND.positive, variant: VARIANT.solid, color: 'rgb(221,221,221)' }
			return { kind: KIND.positive, variant: VARIANT.solid, color: colorCompleted }
		case 'rejected':
			return { kind: KIND.warning, variant: VARIANT.solid, color: colorReject }
		case 'cancelled':
			return { kind: KIND.negative, variant: VARIANT.solid, color: colorCancelled }
		default:
			return { kind: KIND.primary, variant: VARIANT.solid }
	}
}

export default function StatusTag({ status, size }) {
	const props = statusToTagProps(status || undefined)
	
	return (
		<Tag
			closeable={false}
			size={size}
			overrides={{
				Root: {
					style: {
						width: '45px',
						backgroundColor: 'transparent',
						minWidth: 'max-content',
						justifyContent: 'center',
						boxSizing: 'border-box',
						fontSize: '14px',
						color: `#fff !important;`,
						border: `1.5px solid ${props.color} !important`,
						backgroundColor: `${props.color}`,
						fontWeight: '700',
						marginLeft: 0,
						'@media only screen and (max-width: 768px)': {
							width: '42px',
							fontSize: '12px',
						},
					},
				},
			}}
			{...props}
		>
			{humanizeStatus(status)}
		</Tag>
	)
}
