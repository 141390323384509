import React, { createContext, useContext } from "react";
import {BrowserRouter, Route} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

import Projects from "./pages/projects";
import ProjectDetail from "./pages/projects/Detail";
import Clients from "./pages/clients";
import routes, {PrivateRoutes, history} from "./routes";
import {AuthProvider} from "./auth";
import {SignIn} from "./pages/accounts/signin";
import './App.css';
import EmailTemplateList from "./pages/emails";
import EmailTemplateDetail from "./pages/emails/detail";
import Dashboard from "./pages/dashboard";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
            staleTime: 10000,
        }
    }
})


function App() {

    const clientName = React.createContext(null);
    return (
        <BrowserRouter
            getUserConfirmation={((message, callback) => {
                const allowTransition = window.confirm(message);
                callback(allowTransition);
            })}
            history={history}
        >
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <PrivateRoutes>
                        <Route key={1} path={routes.dashboard} element={<Dashboard />} />
                        <Route key={2} path={routes.projects} element={<Projects />} />
                        <Route key={3} path={routes.projectDetail} element={<ProjectDetail />} />
                        <Route key={3} path={routes.clients} element={<Clients />} />
                        <Route key={4} path={routes.signin} element={<SignIn />} />
                        <Route key={5} path={routes.emailTemplates} element={<EmailTemplateList />} />
                        <Route key={5} path={routes.emailTemplatesCreate} element={<EmailTemplateDetail />} />
                        <Route key={6} path={routes.emailTemplatesDetail} element={<EmailTemplateDetail />} />
                        <Route
                            path="*"
                            element={
                                <main style={{ padding: "1rem" }}>
                                    <p>There's nothing here!</p>
                                </main>
                            }
                        />
                    </PrivateRoutes>
                </AuthProvider>
            </QueryClientProvider>
        </BrowserRouter>
    );
}

export default App;
