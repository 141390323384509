import {Button, KIND, SIZE} from "baseui/button";
import React from "react";

export default function WeakButton (props) {
    const {kind, size, overrides, ...restProps} = props;
    const {BaseButton={}, ...restOverrides} = overrides || {};

    return (
        <Button
            kind={kind || KIND.secondary}
            size={size || SIZE.compact}
            overrides={{
                BaseButton: {style: ({$theme}) => ({
                    color: $theme.colors.contentNegative,
                    borderTopRightRadius: $theme.borders.radius200,
                    borderTopLeftRadius: $theme.borders.radius200,
                    borderBottomRightRadius: $theme.borders.radius200,
                    borderBottomLeftRadius: $theme.borders.radius200,
                    borderTopStyle: $theme.borders.border200.borderStyle,
                    borderBottomStyle: $theme.borders.border200.borderStyle,
                    borderLeftStyle: $theme.borders.border200.borderStyle,
                    borderRightStyle: $theme.borders.border200.borderStyle,
                    borderTopWidth: $theme.borders.border200.borderWidth,
                    borderLeftWidth: $theme.borders.border200.borderWidth,
                    borderRightWidth: $theme.borders.border200.borderWidth,
                    borderBottomWidth: $theme.borders.border200.borderWidth,
                    borderTopColor: $theme.borders.border200.borderColor,
                    borderBottomColor: $theme.borders.border200.borderColor,
                    borderLeftColor: $theme.borders.border200.borderColor,
                    borderRightColor: $theme.borders.border200.borderColor,
                    ...BaseButton.style
                })},
                ...restOverrides,
            }}
            {...restProps}
        />
    )
}