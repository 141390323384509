import {useStyletron} from "baseui";
import {useSnackbar, DURATION} from "baseui/snackbar";
import {Check} from "baseui/icon";

export default function ClipboardText (props) {
    const [css, theme] = useStyletron();
    const {enqueue} = useSnackbar();
    return (
        <span
            className={css({
                cursor: 'pointer',
                ':hover': {
                    color: theme.colors.contentAccent
                }
            })}
            onClick={() => {
                enqueue({
                    message: '클립보드에 복사되었습니다',
                    startEnhancer: ({size}) => <Check size={size} />,
                }, DURATION.short)

            }}
            {...props}
        />
    )
}