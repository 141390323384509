import React from "react";

import {Button, SIZE, KIND} from "baseui/button";
import ProjectForm from "../../components/Projects/Form";
import ContentHeader from "../../components/ContentHeader";
import RequestList from "../../components/Projects/RequestList";
import Layout from "../../components/Layout";
import Notification from "../../components/Header/Notification";
import FlexBlock from "../../components/FlexBlock";
import { PlusIcon } from '@heroicons/react/outline'
import { useStyletron } from 'baseui'

export default function Projects () {
    const formModalRef = React.useRef();
    const [css, theme] = useStyletron();

    function openFormModal () {
        const ref = formModalRef.current;
        ref?.reset();
        ref?.setIsOpen(true);
    }

    return (
        <Layout>
            <FlexBlock id="floating-ui">
                <Notification />
                <Button id="button-add-project" onClick={openFormModal} kind={KIND.tertiary} size={SIZE.mini}>
                    <PlusIcon className={css({ stroke: '#fff', strokeWidth: '1', width: '28px', pointerEvents:'none' })} />
                </Button>
            </FlexBlock>
            <RequestList />
            <ProjectForm ref={formModalRef} />
        </Layout>
    )
}