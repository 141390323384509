import React from "react";
import {Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ROLE, SIZE} from "baseui/modal";
import {KIND as ButtonKind} from "baseui/button";
import {zIndexes} from "../../core/options";
import {Checkbox, LABEL_PLACEMENT, STYLE_TYPE} from "baseui/checkbox";
import {useStyletron} from "baseui";


const Settings = React.forwardRef((props, ref) => {
    const [css] = useStyletron();
    const [isOpen, setIsOpen] = React.useState(false);
    const [emailPushChecked, setEmailPushChecked] = React.useState(false);
    const [appPushChecked, setAppPushChecked] = React.useState(false);
    React.useImperativeHandle(ref, () => ({
        setIsOpen,
    }))
    return (
        <Modal
            onClose={() => setIsOpen(false)}
            closeable
            isOpen={isOpen}
            animate
            autoFocus
            size={SIZE.default}
            role={ROLE.dialog}
            overrides={{
                Root: {style: {zIndex: zIndexes.modal}},
            }}
        >
            <ModalHeader className={css({marginLeft: '.5rem'})}>설정</ModalHeader>
            <ModalBody>
                <Checkbox
                    checked={emailPushChecked}
                    checkmarkType={STYLE_TYPE.toggle_round}
                    onChange={e => setEmailPushChecked(e.target.checked)}
                    labelPlacement={LABEL_PLACEMENT.left}
                    overrides={{Root: {style: {justifyContent: 'space-between', padding: '.5rem 1rem'}}}}
                >
                    이메일 알림 허용
                </Checkbox>
                <Checkbox
                    checked={appPushChecked}
                    checkmarkType={STYLE_TYPE.toggle_round}
                    onChange={e => setAppPushChecked(e.target.checked)}
                    labelPlacement={LABEL_PLACEMENT.left}
                    overrides={{Root: {style: {justifyContent: 'space-between', padding: '.5rem 1rem'}}}}
                >
                    LINE 앱 알림 허용
                </Checkbox>
            </ModalBody>
            <ModalFooter>
                <ModalButton
                    onClick={() => {
                        // TODO: reset checked values
                        setIsOpen(false);
                    }}
                    kind={ButtonKind.tertiary}
                >
                    취소
                </ModalButton>
                <ModalButton>변경</ModalButton>
            </ModalFooter>
        </Modal>
    );
})

export default Settings;