import { Modal, ModalBody, ModalFooter, ROLE, SIZE } from "baseui/modal";
import { useStyletron } from "baseui";
import React, { useEffect, useRef, useState } from "react";
import { Block } from "baseui/block";
import { Tag, VARIANT } from "baseui/tag";
import FlexBlock from "../FlexBlock";
import { Button, KIND } from "baseui/button";
import { Step, StepProgressBar } from "./steps";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Textarea } from "baseui/textarea";
import { DatePicker } from "baseui/datepicker";
import { Select } from "baseui/select";
import { Checkbox } from "baseui/checkbox";
import spacetime from "spacetime";
import { LabelLarge, LabelSmall } from "baseui/typography";
import "react-step-progress/dist/index.css";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { flattenFormData } from "../../core/utils";
import { listClient, createPaper, createPaperEtc, uploadFile } from "../../core/apis";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "baseui/snackbar";
import { Check } from "baseui/icon";
import { RequestOptionEnum } from "../../core/options";
import qs from "qs";
import useDebounce from "../../core/hooks/useDebounce";

const RequestTypeForm = React.forwardRef((props, ref) => {
    const [css, theme] = useStyletron();
    const [group, setGroup] = React.useState(RequestOptionEnum.group.project);

    React.useImperativeHandle(ref, () => ({
        getFormData() {
            return { group };
        },
        hasError() {
            return Object.values(RequestOptionEnum.group).indexOf(group) < 0;
        },
    }));

    return (
        <React.Fragment>
            <FlexBlock flexWrap="wrap" justifyContent="space-evenly" className="form-block">
                <Block
                    className={css({
                        width: "350px",
                        height: "150px",
                        padding: "16px",
                        marginBottom: "1rem",
                        cursor: "pointer",
                        position: "relative",
                        border: group === RequestOptionEnum.group.project ? `1px solid var(--black)` : `1px solid #e5e7ec`,
                        borderRadius: '5px',
                        ":hover": {
                            boxShadow: 'unset'
                        },
                    })}
                    onClick={() => {
                        setGroup(RequestOptionEnum.group.project);
                    }}>
                    {group === RequestOptionEnum.group.project && (
                        <CheckCircleIcon
                            className={css({
                                width: "24px",
                                position: "absolute",
                                right: "15px",
                                top: "14px",
                                fill: theme.colors.contentPrimary,
                            })}
                        />
                    )}
                    <LabelLarge>프로젝트 의뢰</LabelLarge>
                    <p className={css({ ...theme.typography.font150, marginTop: "6px" })}>컨설팅 및 실구축 관련 프로젝트 의뢰입니다.</p>
                </Block>
                <Block
                    className={css({
                        width: "350px",
                        height: "150px",
                        padding: "16px",
                        marginBottom: "1rem",
                        cursor: "pointer",
                        position: "relative",
                        borderRadius: '5px',
                        border: group === RequestOptionEnum.group.etc ? `1px solid var(--black)` : `1px solid #e5e7ec`,
                        ":hover": {
                            boxShadow: 'none'
                        },
                    })}
                    onClick={() => {
                        setGroup(RequestOptionEnum.group.etc);
                    }}>
                    {group === RequestOptionEnum.group.etc && (
                        <CheckCircleIcon
                            className={css({
                                width: "24px",
                                position: "absolute",
                                right: "15px",
                                top: "14px",
                                fill: theme.colors.contentPrimary,
                            })}
                        />
                    )}
                    <LabelLarge>기타 의뢰</LabelLarge>
                    <p className={css({ ...theme.typography.font150, marginTop: "6px" })}>언론 홍보, 인터뷰 요청 등의 미디어/마케팅 관련 의뢰입니다.</p>
                </Block>
            </FlexBlock>
        </React.Fragment>
    );
});
export const RequestBaseForm = React.forwardRef((props, ref) => {
    const { type } = props;
    const [title, setTitle] = React.useState("");
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [is_schedule_adjustable, setScheduleAdjustable] = React.useState(false);
    const [titleInvalid, setTitleInvalid] = React.useState(false);
    const [endDateInvalid, setEndDateInvalid] = React.useState(false);

    React.useImperativeHandle(ref, () => ({
        getFormData() {
            return {
                title,
                start_date: startDate ? spacetime(startDate).format("days") : null,
                end_date: endDate ? spacetime(endDate).format("days") : null,
                is_schedule_adjustable,
            };
        },
        hasError() {
            const titleValid = typeof title === "string" && title.trim().length > 0;
            const endDateValid = startDate && endDate ? spacetime(startDate).isBefore(spacetime(endDate)) : true;
            setTitleInvalid(!titleValid);
            setEndDateInvalid(!endDateValid);
            return !titleValid || !endDateValid;
        },
    }));

    const [placeHolder, setPlaceHolder] = React.useState(type === `project` ? `프로젝트명` : `의뢰명`)

    return (
        <Block>
            <FormControl label={() => placeHolder} caption={() => "[필수] 1자 이상 입력해 주세요"}>
                <Input name="title" value={title} error={titleInvalid} onChange={(e) => setTitle(e.currentTarget.value)} placeholder={`${placeHolder}을 입력해 주세요.`} />
            </FormControl>
            {type === 'project' && 
                <>
                    <FlexBlock flexDirection="column">
                        <Block>
                            <FormControl label={() => "예상 시작일"} caption={() => "[선택] 프로젝트 시작일을 입력해 주세요"}>
                                <DatePicker
                                    aria-label="Select a date"
                                    clearable={true}
                                    initialState={{ value: new Date() }}
                                    overrides={{
                                        Input: { props: { name: "start_date" } },
                                        Day: {
                                            style: ({ $theme, $selected, $isHovered, $startDate, $isHighlighted }) => ({
                                                backgroundColor: $selected ? "#000" : $isHovered || $isHighlighted ? "#f0f0f0" : "transparent",
                                                border: $startDate ? "2px solid #000" : "none",
                                            }),
                                        },
                                    }}
                                    formatString="yyyy-MM-dd"
                                    value={startDate}
                                    onChange={(val) => setStartDate(val.date)}
                                    highlightedDate={spacetime.now().toNativeDate()}
                                />
                            </FormControl>
                        </Block>
                        <Block>
                            <FormControl label={() => "예상 종료일"} caption={() => "[선택] 프로젝트 종료일을 입력해 주세요"}>
                                <DatePicker
                                    aria-label="Select a date"
                                    clearable={true}
                                    // initialState={{value: []}}
                                    formatString="yyyy-MM-dd"
                                    value={endDate}
                                    onChange={(val) => setEndDate(val.date)}
                                    startDateLabel={"toady"}
                                    overrides={{
                                        Input: { props: { name: "end_date" } },
                                        Day: {
                                            style: ({ $theme, $selected, $isHovered, $startDate, $isHighlighted }) => ({
                                                backgroundColor: $selected ? "#000" : $isHovered || $isHighlighted ? "#f0f0f0" : "transparent",
                                                border: $startDate ? "2px solid #000" : "none",
                                            }),
                                        },
                                    }}
                                    highlightedDate={spacetime.now().toNativeDate()}
                                    error={endDateInvalid}
                                />
                            </FormControl>
                        </Block>
                    </FlexBlock>
                    <Block marginBottom="2rem">
                        <Checkbox
                            name="is_schedule_adjustable"
                            checked={is_schedule_adjustable}
                            onChange={(e) => {
                                setScheduleAdjustable(e.target.checked);
                            }}
                            overrides={{
                                Label: { style: { fontSize: "14px" } },
                                Checkmark: { style: { borderTopWidth: "2px", borderBottomWidth: "2px", borderRightWidth: "2px", borderLeftWidth: "2px" } },
                            }}>
                            일정 조율 가능 여부
                        </Checkbox>
                    </Block>
                </>
            }
        </Block>
    );
});

export const RequestAdditionalForm = React.forwardRef((props, ref) => {
    const [description, setDescription] = React.useState("");
    const [_attachments, setAttachments] = React.useState([]);
    const [displayAttachments, setDisplayAttachments] = React.useState([]);

    const mutation = useMutation(uploadFile, {
        onSuccess: (res) => {
            setDisplayAttachments((prev) => [...prev, res]);
            // reset();
            // queryClient.invalidateQueries(["files", { paper: paperId }]).then(() => {});
        },
    });

    useEffect(() => {
        if (displayAttachments) {
            const newArr = [];
            displayAttachments.map((f) => {
                newArr.push(f.id);
            });

            setAttachments(newArr);
            // setAttachments(displayAttachments.filter((f) => f.id));
        }
    }, [displayAttachments]);

    const fileRef = useRef();

    React.useImperativeHandle(
        ref,
        () => ({
            getFormData() {
                return {
                    description: description,
                    // attachments: _attachments,
                };
            },
            hasError() {
                return false;
            },
        }),
        [description]
    );

    function handleDrop(acceptedFiles) {
        const file = acceptedFiles[0];
        const formData = new FormData();
        formData.append("file", file);
        mutation.mutate(formData);
    }

    const deleteFile = React.useCallback((name) => {
        const _newFiles = _attachments.filter((file) => file[0].name !== name);
        setAttachments(_newFiles);
    }, []);

    // const uploadFile = React.useCallback((file) => {
    //     const _newFile = e.target.files[0];
    // });

    return (
        <Block>
            <FormControl label={() => "추가 정보"}>
                <Textarea
                    name="description"
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value);
                    }}
                    placeholder="추가 전달 내용이 있으시면 입력해 주세요."
                />
            </FormControl>
            {/* <Block>
                <FormControl label={() => "파일 첨부"}>
                    <Block>
                        <div className="file-wrap">
                            <div className="file-container">
                                {displayAttachments &&
                                    displayAttachments.map((file) => {
                                        return (
                                            <Tag
                                                key={`file_${file.id}`}
                                                onClick={(e) => {
                                                    deleteFile(file.title);
                                                }}>
                                                {file.title}
                                            </Tag>
                                        );
                                    })}
                            </div>
                            <button className="button-file">
                                <Input
                                    type="file"
                                    onClick={(e) => (e.target.value = null)}
                                    onChange={(e) => {
                                        // uploadFile(e);
                                        handleDrop(e.target.files);
                                        // file.forEach(f => )
                                    }}
                                    ref={fileRef}
                                />
                            </button>
                        </div>
                    </Block>
                </FormControl>
            </Block> */}
        </Block>
    );
});
export const ProjectRequestForm = React.forwardRef((props, ref) => {
    const [project_categories, setProjectCategories] = React.useState(props.project_categories || []);
    const [domain, setDomain] = React.useState(props.domain || []);
    const [budget, setBudget] = React.useState(props.budget || []);
    const [categoryInvalid, setCategoryInvalid] = React.useState(false);
    const [domainInvalid, setDomainInvalid] = React.useState(false);
    const [budgetInvalid, setBudgetInvalid] = React.useState(false);

    React.useImperativeHandle(ref, () => ({
        getFormData() {
            return { project_categories, domain, budget: budget[0]?.value };
        },
        hasError() {
            const categoryValid = project_categories.length > 0;
            const domainValid = domain.length > 0;
            const budgetValid = budget.length > 0;
            setCategoryInvalid(!categoryValid);
            setDomainInvalid(!domainValid);
            setBudgetInvalid(!budgetValid);
            return !categoryValid || !domainValid || !budgetValid;
        },
    }));
    return (
        <Block>
            <Block>
                <FormControl label={() => "업무 범위"} caption={() => "[필수] 중복 선택 가능"}>
                    <Select
                        options={RequestOptionEnum.project.tasks}
                        labelKey="label"
                        valueKey="value"
                        onChange={(params) => setProjectCategories(params.value)}
                        value={project_categories}
                        searchable={false}
                        clearable={false}
                        placeholder=""
                        multi
                        error={categoryInvalid}
                    />
                </FormControl>
                <FormControl label={() => "의뢰 범위"} caption={() => "[필수] 중복 선택 가능"}>
                    <Select
                        options={RequestOptionEnum.project.range}
                        labelKey="label"
                        valueKey="value"
                        onChange={(params) => setDomain(params.value)}
                        value={domain}
                        searchable={false}
                        clearable={false}
                        placeholder=""
                        multi
                        error={domainInvalid}
                    />
                </FormControl>
                <FormControl label={() => "예산"} caption={() => "[필수] 단일 선택 가능"}>
                    <Select
                        options={RequestOptionEnum.project.budget}
                        labelKey="label"
                        valueKey="value"
                        onChange={(params) => setBudget(params.value)}
                        value={budget}
                        searchable={false}
                        clearable={false}
                        placeholder=""
                        error={budgetInvalid}
                    />
                </FormControl>
            </Block>
        </Block>
    );
});
const EtcRequestForm = React.forwardRef((props, ref) => {
    const [request_type, setRequestType] = React.useState([]);
    const [requestTypeInvalid, setRequestTypeInvalid] = React.useState(false);
    React.useImperativeHandle(ref, () => ({
        getFormData() {
            return { request_type: request_type[0]?.value };
        },
        hasError() {
            const requestTypeValid = request_type.length > 0;
            setRequestTypeInvalid(!requestTypeValid);
            return !requestTypeValid;
        },
    }));
    return (
        <Block>
            <FormControl label={() => "의뢰 범위"} caption={"[필수] 단일 선택"}>
                <Select
                    options={RequestOptionEnum.etc}
                    labelKey="label"
                    valueKey="value"
                    onChange={(params) => setRequestType(params.value)}
                    value={request_type}
                    searchable={false}
                    clearable={false}
                    error={requestTypeInvalid}
                />
            </FormControl>
        </Block>
    );
});
const ClientForm = React.forwardRef((props, ref) => {
    // TODO: 클라이언트 담당자 폼

    const [css] = useStyletron();
    const [company, setCompany] = React.useState([]);
    const [companyInvalid, setCompanyInvalid] = React.useState(false);
    const {
        isLoading,
        data = [],
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useInfiniteQuery("clients", listClient, {
        getNextPageParam: (lastPage) => {
            if (lastPage.next) {
                const { page } = qs.parse(lastPage.next.split("?")[1]);
                return parseInt(page);
            }
            return false;
        },
        keepPreviousData: true,
        staleTime: 1000 * 60 * 10,
        select: (data) => data.pages.flatMap((page) => page.results.map((row) => ({ label: row.name, value: row.id }))),
    });
    const [value, setValue] = React.useState("");
    const debouncedValue = useDebounce(value, 100);

    React.useEffect(() => {
        if (debouncedValue && hasNextPage) {
            fetchNextPage().then((r) => r);
        }
    }, [debouncedValue]);
    const [name, setName] = React.useState("");
    const [nameInvalid, setnameInvalid] = React.useState(false);

    const [companyName, setCompanyName] = React.useState("");
    const [companyNameInvalid, setcompanyNameInvalid] = React.useState(false);

    const [companyEmail, setCompanyEmail] = React.useState("");
    const [companyEmailInvalid, setcompanyEmailInvalid] = React.useState(false);

    const [companyPhoneNumber, setCompanyPhoneNumber] = React.useState("");
    const [companyPhoneNumberInvalid, setcompanyPhoneNumberInvalid] = React.useState(false);

    const [companyDepartment, setCompanyDepartment] = React.useState("");
    const [companyPosition, setCompanyPosition] = React.useState("");

    React.useImperativeHandle(ref, () => ({
        getFormData() {
            return {
                assignee: {
                    name: name,
                    company: {
                        name: companyName,
                    },
                    phone: companyPhoneNumber,
                    department: companyDepartment,
                    position: companyPosition,
                    email: companyEmail,
                },
            };
        },
        hasError() {
            const nameValid = name.length > 0;
            const companyNameValid = companyName.length > 0;
            const companyEmailValid = companyEmail.length == 0 ? true : /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/.test(companyEmail);
            const companyPhoneNumberValid = companyPhoneNumber.length > 11;

            setnameInvalid(!nameValid);
            setcompanyNameInvalid(!companyNameValid);
            setcompanyEmailInvalid(!companyEmailValid);
            setcompanyPhoneNumberInvalid(!companyPhoneNumberValid);
            return !nameValid || !companyNameValid || !companyEmailValid || !companyPhoneNumberValid;
        },
    }));

    useEffect(() => {
        setCompanyPhoneNumber(
            companyPhoneNumber
                .replace(/[^0-9]/g, "")
                .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
                .replace(/(\-{1,2})$/g, "")
        );
    }, [companyPhoneNumber]);
    return (
        <React.Fragment>
            <LabelLarge
                className={css({
                    paddingBottom: "10px",
                    borderBottom: "1px solid #000",
                    marginBottom: "16px",
                })}>
                의뢰하시는 분의 정보를 적어주세요.
            </LabelLarge>
            <FormControl label={() => "성함 *"}>
                <Input name="name" value={name} error={nameInvalid} onChange={(e) => setName(e.currentTarget.value)} placeholder="성함을 입력해 주세요." />
            </FormControl>
            <FormControl label={() => "회사/기업 *"}>
                <Input
                    name="companyName"
                    value={companyName}
                    error={companyNameInvalid}
                    onChange={(e) => setCompanyName(e.currentTarget.value)}
                    placeholder="회사/기업명을 입력해 주세요."
                />
            </FormControl>
            <FormControl label={() => "이메일 *"}>
                <Input
                    name="companyEmail"
                    value={companyEmail}
                    error={companyEmailInvalid}
                    onChange={(e) => setCompanyEmail(e.currentTarget.value)}
                    placeholder="이메일을 입력해 주세요."
                />
            </FormControl>
            <FormControl label={() => "연락처 *"}>
                <Input
                    name="companyPhoneNumber"
                    maxLength="13"
                    value={companyPhoneNumber}
                    error={companyPhoneNumberInvalid}
                    onChange={(e) => setCompanyPhoneNumber(e.currentTarget.value)}
                    placeholder="연락처를 입력해 주세요."
                />
            </FormControl>
            <FormControl label={() => "부서(선택)"}>
                <Input name="companyDepartment" value={companyDepartment} onChange={(e) => setCompanyDepartment(e.currentTarget.value)} placeholder="부서(선택)를 입력해 주세요." />
            </FormControl>
            <FormControl label={() => "직급(선택)"}>
                <Input name="companyPosition" value={companyPosition} onChange={(e) => setCompanyPosition(e.currentTarget.value)} placeholder="직급(선택)을 입력해 주세요." />
            </FormControl>
        </React.Fragment>
    );
});

const ProjectForm = React.forwardRef((props, ref) => {
    const [css] = useStyletron();
    const formRef = React.useRef([]);
    const { enqueue } = useSnackbar();
    const defaultFormData = {
        group: RequestOptionEnum.group.project,
        title: "",
        start_date: null,
        end_date: null,
        attachments: [],
        is_schedule_adjustable: false,
        // project_categories: [],
        domain: [],
        description: "",
        // budget: null,
        through_contact: false,
        // request_type: null,
        assignee: {},
        // attachments: {}
    };
    const [isOpen, setIsOpen] = React.useState(false);
    const [current, setCurrent] = React.useState(0);
    const [formData, setFormData] = React.useState(defaultFormData);
    const [formErrors, setFormErrors] = React.useState([false, false, false, false]);
    const snackbar = useSnackbar();
    const queryClient = useQueryClient();

    const mutation = useMutation(createPaper, {
        onSuccess: () => {
            queryClient.invalidateQueries("papers", { exact: false });
            snackbar.enqueue({ message: "의뢰가 생성되었습니다.", startEnhancer: ({ size }) => <Check size={size} /> });
            setIsOpen(false);
        },
        onError: (error) => {
            enqueue({
                message: (
                    <React.Fragment>
                        <div>의뢰를 생성할 수 없습니다. 관리자에게 문의하세요.</div>
                        <LabelSmall color="#fff">[{error.message}]</LabelSmall>
                    </React.Fragment>
                ),
                overrides: { Content: { style: ({ $theme }) => ({ backgroundColor: $theme.colors.negative500 }) } },
            });
        },
    });

    const mutation_etc = useMutation(createPaperEtc, {
        onSuccess: () => {
            queryClient.invalidateQueries("papers", { exact: false });
            snackbar.enqueue({ message: "의뢰가 생성되었습니다.", startEnhancer: ({ size }) => <Check size={size} /> });
            setIsOpen(false);
        },
        onError: (error) => {
            enqueue({
                message: (
                    <React.Fragment>
                        <div>의뢰를 생성할 수 없습니다. 관리자에게 문의하세요.</div>
                        <LabelSmall color="#fff">[{error.message}]</LabelSmall>
                    </React.Fragment>
                ),
                overrides: { Content: { style: ({ $theme }) => ({ backgroundColor: $theme.colors.negative500 }) } },
            });
        },
    });

    const handleNextClick = (e) => {
        const hasError = formRef.current[current].hasError();
        setFormErrors((prev) => {
            prev[current] = hasError;
            return [...prev];
        });
        if (!hasError) {
            setFormData((prevState) => ({ ...prevState, ...formRef.current[current].getFormData() }));
            setCurrent(current + 1);
        }
    };
    const handleFormSubmit = () => {
        const payload = flattenFormData({ ...formData, ...formRef.current[current].getFormData() });
        mutation.mutate(payload);
    };
    const handleFormSubmitEtc = () => {
        const payload = flattenFormData({ ...formData, ...formRef.current[current].getFormData() });
        mutation_etc.mutate(payload);
    };

    React.useImperativeHandle(ref, () => ({
        reset() {
            setCurrent(0);
            setFormData(defaultFormData);
            setFormErrors([false, false, false, false]);
        },
        setIsOpen,
    }));

    return (
        <Modal
            onClose={(args) => {
                if (args.closeSource !== "backdrop") setIsOpen(false);
            }}
            closeable
            isOpen={isOpen}
            animate
            autoFocus
            size={SIZE.full}
            role={ROLE.alertdialog}
            overrides={{
                Root: { style: { zIndex: 20 } },
                Dialog: {
                    style: { maxWidth: "850px" },
                    props: {
                        onClick: () => {
                        },
                    },
                },
            }}
            ref={ref}>
            <ModalBody id="create-request">
                <StepProgressBar current={current} errors={formErrors}>
                    <Step label="의뢰유형">
                        <LabelLarge
                            className={css({
                                paddingBottom: "10px",
                                borderBottom: "1px solid #000",
                                marginBottom: "16px",
                            })}>
                            의뢰 유형을 선택해 주세요
                        </LabelLarge>
                        <RequestTypeForm ref={(el) => (formRef.current[0] = el)} />
                    </Step>
                    <Step label="클라이언트">
                        <FlexBlock flexWrap="wrap" flexDirection="column">
                            <ClientForm ref={(el) => (formRef.current[1] = el)} />
                        </FlexBlock>
                    </Step>
                    <Step label="의뢰정보">
                        <FlexBlock flexWrap="wrap" flexDirection="column">
                            <LabelLarge
                                className={css({
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #000",
                                    marginBottom: "16px",
                                })}>
                                기본 정보를 입력해 주세요
                            </LabelLarge>
                            <RequestBaseForm ref={(el) => (formRef.current[2] = el)} type={formData.group} />
                        </FlexBlock>
                    </Step>
                    <Step label="의뢰범위">
                        <FlexBlock flexWrap="wrap" flexDirection="column">
                            <LabelLarge
                                className={css({
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #000",
                                    marginBottom: "16px",
                                })}>
                                추가정보를 선택해 주세요
                            </LabelLarge>
                            {formData.group === RequestOptionEnum.group.project ? (
                                <ProjectRequestForm ref={(el) => (formRef.current[3] = el)} />
                            ) : (
                                <EtcRequestForm ref={(el) => (formRef.current[3] = el)} />
                            )}
                        </FlexBlock>
                    </Step>
                    <Step label="추가 정보">
                        <FlexBlock flexWrap="wrap" flexDirection="column">
                            <LabelLarge
                                className={css({
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #000",
                                    marginBottom: "16px",
                                })}>
                                추가 전달 내용을 입력해 주세요
                            </LabelLarge>
                            <RequestAdditionalForm ref={(el) => (formRef.current[4] = el)} />
                        </FlexBlock>
                    </Step>
                </StepProgressBar>
            </ModalBody>
            <ModalFooter>
                {current > 0 && (
                    <Button
                        id="button-prev"
                        type="button"
                        kind={KIND.secondary}
                        overrides={{ BaseButton: { style: { marginRight: "1rem" } } }}
                        onClick={(e) => {
                            setCurrent(current - 1);
                        }}>
                        이전
                    </Button>
                )}
                {current < 4 ? (
                    <Button type="button" onClick={handleNextClick}>
                        다음
                    </Button>
                ) : (
                    <>
                        {formData.group === RequestOptionEnum.group.project ? (
                            <Button type="button" isLoading={mutation.isLoading} onClick={handleFormSubmit}>
                                제출
                            </Button>
                        ) : (
                            <Button type="button" isLoading={mutation_etc.isLoading} onClick={handleFormSubmitEtc}>
                                제출
                            </Button>
                        )}
                    </>
                )}
            </ModalFooter>
        </Modal>
    );
});
export default ProjectForm;
