import Header from './Header/Header'
import { useStyletron } from 'baseui'
import Sidebar from './Sidebar/Sidebar'
import React, { useLayoutEffect, useState } from 'react'
import { Block } from 'baseui/block'
import { backtickInputRegex } from '@milkdown/preset-commonmark'

const Wrapper = ({ children, ...props }) => {
	return (
		<Block display="flex" flexDirection="column" height="100%" {...props}>
			{children}
		</Block>
	)
}

const Container = ({ children, ...props }) => {
	return (
		<Block display="flex" flex="1" {...props}>
			{children}
		</Block>
	)
}

const Body = ({ children }) => {
	const [css, theme] = useStyletron()
	return (
		<div
			className={css({
				display: 'flex',
				overflow: 'auto',
				height: '100%',
				flex: '1',
				padding: '0',
				backgroundColor: theme.colors.backgroundSecondary,
				flexDirection: 'column',
				backgroundColor: 'green'
			})}
		>
			{children}
		</div>
	)
}

const mql = window.matchMedia(`(min-width: 1136px)`)
const CONTENT_WIDTH = 1300

export default function Layout({ children, ...restProps }) {
	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [sidebarDocked, setSidebarDocked] = useState(mql.matches)

	const mediaQueryChanged = () => {
		setSidebarOpen(false)
		setSidebarDocked(mql.matches)
	}

	const handleClickBurger = () => {
		if (!mql.matches) {
			setSidebarOpen(!sidebarOpen)
		} else {
			setSidebarOpen(false)
			setSidebarDocked(!sidebarDocked)
		}
	}

	useLayoutEffect(() => {
		// document.querySelector('html').style.backgroundColor = theme.colors.backgroundSecondary;
		// document.body.style.backgroundColor = theme.colors.backgroundSecondary;
		mql.addEventListener('change', mediaQueryChanged)
	}, [])
	return (
		<Wrapper>
			<Container>
				<Sidebar sidebarOpen={sidebarOpen} sidebarDocked={sidebarDocked} setSidebarOpen={setSidebarOpen} $style={{ width: '260px', }}>
					<Body id="test">
						<Header handleClickBurger={handleClickBurger} />
						<Block
							id="content"
							padding="2rem 3.8rem"
							$style={{
								width: '100%',
								maxWidth: `${CONTENT_WIDTH}px`,
								// margin:'0 auto',
								boxSizing: 'border-box',
								marginTop: '20px',
								marginBottom: '80px',
								
							}}
						>
							<Block id="base-block" style={{padding: '0 50px', backgroundColor: '#fff', borderRadius: '10px', boxShadow: 'rgba(225, 225, 225, 0.4) 0px 3px 10px' }}>
							{children}
							</Block>
						</Block>
					</Body>
				</Sidebar>
			</Container>
		</Wrapper>
	)
}
