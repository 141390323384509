import {StatefulMenu} from "baseui/menu";
import FlexBlock from "../FlexBlock";
import {Block} from "baseui/block";
import {Check, TriangleDown} from "baseui/icon";
import {PLACEMENT, StatefulPopover} from "baseui/popover";
import {Button, KIND} from "baseui/button";
import React, {useState} from "react";
import {useStyletron} from "baseui";

export default function SortPopover ({data, onItemSelect}) {
    const [css, theme] = useStyletron();
    data = [
        {
            label: '최신 순',
            value: 'publication_desc'
        },
        {
            label: '오래된 순',
            value: 'publication_asc'
        }
    ]
    const [selected, setSelected] = useState('publication_desc');
    function handleSelectItem ({item}) {
        setSelected(item.value);
        onItemSelect && onItemSelect(item);
    }
    const parsed = data.map((row, index) => ({
        label: (
            <FlexBlock>
                <Block width='25px' className={css({textAlign: 'center'})}>
                    {(row.value === selected) && <Check size={18} overrides={{Svg: {style: {verticalAlign: 'text-bottom'}}}} />}
                </Block>
                <Block paddingLeft='5px'>{row.label}</Block>
            </FlexBlock>
        ),
        value: row.value
    }))
    return (
        <StatefulPopover
            content={() => (
                <StatefulMenu
                    items={parsed}
                    onItemSelect={handleSelectItem}
                    overrides={{
                        Option: { props: { overrides: { ListItem: {style: {paddingLeft: '8px'}} } } }
                    }}
                />
            )}
            returnFocus
            autoFocus
            placement={PLACEMENT.bottomRight}
            overrides={{
                Body: {
                    style: {
                        boxShadow: 'unset',
                        width: '150px',
                        ...theme.borders.border100,
                    }
                },
                Inner: {
                    style: {
                        backgroundColor: theme.colors.backgroundPrimary
                    }
                }
            }}
        >

            <Button
                kind={KIND.tertiary}
                endEnhancer={<TriangleDown size={18} />}
                overrides={{
                    BaseButton: {style: {...theme.typography.font200, paddingLeft: '0', paddingRight: '0', paddingTop: '0', paddingBottom: '0'}},
                    EndEnhancer: {style: {marginLeft: '6px'}}
                }}
                className={css({height:'100%'})}
            >
                정렬
            </Button>
        </StatefulPopover>
    )
}