import { styled } from 'baseui'
import { Chart } from 'chart.js'
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'

const VALUE_INFOS = [
	{
		name: 'new',
		backgroundColor: 'rgba(75, 202, 129, 1)',
		label: '의뢰 접수 ',
	},
	{
		name: 'in_progress',
		backgroundColor: 'rgba(75, 202, 129, 0.5)',
		label: '협의 진행 ',
	},
	{
		name: 'aligning',
		backgroundColor: 'rgba(75, 202, 129, 0.2)',
		label: '착수 ',
	},
]

const drawValueOnCanvas = function (chart) {
	if (!('ontouchstart' in document.documentElement)) return
	const ctx = chart.ctx

	ctx.save()
	ctx.textAlign = 'center'
	ctx.font = '700 10px sans-serif'
	ctx.fillStyle = '#fff'
	ctx.shadowColor = 'rgba(67, 59, 59, 0.4)'
	ctx.shadowBlur = 4

	const datasets = chart.data.datasets
	for (let i = 0; i < datasets.length; i++) {
		const dataset = datasets[i]
		const meta = chart.getDatasetMeta(i)

		for (let j = 0; j < meta.data.length; j++) {
			const bar = meta.data[j]
			const value = dataset.data[j]
			if (Number(value) !== 0) {
				const height = bar.height
				ctx.fillText(value, bar.x, bar.y + height / 2 + 5)
			}
		}
	}
	ctx.restore()
}

export default function MovingProjectChart(props) {
	const { movingProjects } = props

	const [isLoading, setIsLoading] = useState(true)
	const [barData, setBarData] = useState({
		labels: [],
		datasets: [],
	})

	const [isMobileCanvas, setIsMobileCanvas] = useState(false)
	const [isInitiated, setIsInitiated] = useState(false)

	const initCanvas = (ctx, size) => {
		if (size.width <= 768) {
			ctx.options.scales.x.ticks.font = {
				size: 10,
			}
			ctx.options.scales.y.ticks.font = {
				size: 10,
			}
			ctx.options.aspectRatio = 1.2

			setIsMobileCanvas(true)
		} else {
			ctx.options.scales.x.ticks.font = {
				size: 12,
			}
			ctx.options.scales.y.ticks.font = {
				size: 12,
			}
			ctx.options.aspectRatio = 2
			setIsMobileCanvas(false)
		}
		ctx.update()
	}

	const barOptions = {
		responsive: true,
		animation: {
			onComplete: function (e) {
				const chart = e.chart

				drawValueOnCanvas(chart)
				if (e.initial) {
					setIsInitiated(true)
				}
			},
		},
		scales: {
			x: {
				stacked: true,
				grid: {
					color: 'rgba(229, 229, 229, 0.3)',
				},
				ticks: {
					font: function () {
						const size = isMobileCanvas ? 10 : 12

						return {
							size,
						}
					},
				},
			},
			y: {
				stacked: true,
				grid: {
					color: 'rgba(229, 229, 229, 0.3)',
				},
				ticks: {
					font: function () {
						const size = isMobileCanvas ? 10 : 12

						return {
							size,
						}
					},
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: !('ontouchstart' in document.documentElement),
			},
		},
		onResize: (ctx, size) => {
			if (isInitiated) {
				ctx.options.animation.duration = 0
			}
			initCanvas(ctx, size)
		},
	}

	const splitDate = (date) => {
		const d = date.split('-')
		return `${d[0].slice(-2)}.${d[1]}`
	}

	useEffect(() => {
		const fetchBarData = async () => {
			const tempData = {
				labels: [],
				datasets: [],
			}
			const valueData = {}
			for (const info of VALUE_INFOS) {
				valueData[info.name] = {
					data: [],
					backgroundColor: info?.backgroundColor,
					hoverBackgroundColor: info?.backgroundColor,
					label: info?.label,
					// borderWidth: 1,
				}
			}

			for (const project of movingProjects) {
				const { date, detail } = project

				tempData.labels.push(splitDate(date))

				for (const info of VALUE_INFOS) {
					valueData[info.name].data.push(detail[info.name])
				}
			}

			tempData.datasets.push(...Object.values(valueData))

			return tempData
		}

		setIsLoading(true)
		fetchBarData().then((result) => {
			setBarData(result)
			setIsLoading(false)
		})
	}, [movingProjects])

	return (
		<div>
			<CustomLegend>
				{VALUE_INFOS.map((value, i) => (
					<LegendItem key={i}>
						<ColorBox style={{ backgroundColor: value.backgroundColor }} />
						{value.label}
					</LegendItem>
				))}
			</CustomLegend>
			{!isLoading && (
				<>
					<Bar data={barData} options={barOptions} />
					<CustomTicksContainer style={{ width: isMobileCanvas ? 'calc(100% - 20px)' : 'calc(100% - 27px)' }}>
						{movingProjects.map((project, i) => (
							<CustomTicks
								key={i}
								style={{
									fontSize: isMobileCanvas ? '10px' : '12px',
								}}
							>
								<div>
									전체<NoMobile> : </NoMobile>
									<CountOnTicks>{project.total_count}</CountOnTicks>
								</div>
								<div>
									거절<NoMobile> : </NoMobile>
									<CountOnTicks>{project.detail.rejected}</CountOnTicks>
								</div>
								<div>
									종료<NoMobile> : </NoMobile>
									<CountOnTicks>{project.detail.completed}</CountOnTicks>
								</div>
								{/* <div>
								<ColorBox style={{backgroundColor:VALUE_INFOS[0].backgroundColor}} />
								{project.detail.pending}
							</div>
							<div>
								<ColorBox style={{backgroundColor:VALUE_INFOS[1].backgroundColor}} />
								{project.detail.processing}
							</div>
							<div>
								<ColorBox style={{backgroundColor:VALUE_INFOS[2].backgroundColor}} />
								{project.detail.ongoing}
							</div> */}
							</CustomTicks>
						))}
					</CustomTicksContainer>
				</>
			)}
		</div>
	)
}

const CustomLegend = styled('div', ({ $theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(3, auto)',
	columnGap: '15px',
	justifyContent: 'center',
	fontSize: '12px',
	color: '#666666',
	paddingBottom: '20px',
}))

const LegendItem = styled('div', ({ $theme }) => ({}))

const ColorBox = styled('span', ({ $theme }) => ({
	width: '10px',
	height: '10px',
	display: 'inline-block',
	marginRight: '10px',
}))

const CustomTicksContainer = styled('div', {
	display: 'flex',
	width: 'calc(100% - 27px)',
	marginLeft: 'auto',
	marginTop: '10px',
})

const CustomTicks = styled('div', {
	width: 'calc(100% / 12)',
	display: 'grid',
	gridTemplateColumns: '1fr',
	alignItems: 'center',
	textAlign: 'center',
	rowGap: '5px',
	fontSize: '12px',
	lineHeight: 1.2,
	fontStyle: 'normal',
	fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
	color: '#666666',
	padding: '0 5px',
	'@media screen and (max-width:768px)': {
		padding: '0',
		rowGap: '10px',
	},
})

const CountOnTicks = styled('span', {
	fontWeight: 500,
	'@media screen and (max-width:768px)': {
		display: 'block',
	},
})

const NoMobile = styled('span', {
	'@media screen and (max-width:768px)': {
		display: 'none',
	},
})
