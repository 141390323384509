import React from "react";
import {Input, SIZE} from "baseui/input";
import {ChevronRight, Search as SearchIcon} from "baseui/icon";
import {styled} from "baseui";
import {PLACEMENT, Popover} from "baseui/popover";
import {LabelSmall} from "baseui/typography";
import FlexBlock from "../FlexBlock";
import {ListItem, ListItemLabel} from "baseui/list";
import {useQuery} from "react-query";
import {searchPapers} from "../../core/apis";
import useDebounce from "../../core/hooks/useDebounce";

import Link from "../Link";
import {Spinner} from "baseui/spinner";


function useSearch (q) {
    return useQuery(['papers', q], searchPapers, {
        staleTime: 1000 * 60 * 5,
        keepPreviousData: true,
        enabled: !!q,
    })
}
const ShortCutBlock = styled('div', ({$theme}) => ({
    width: '25px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    backgroundColor: $theme.colors.backgroundSecondary,
    borderRadius: $theme.borders.radius300,
    ...$theme.borders.border200
}))

const InputWrapper = styled('span', {
    width: '100%',
})

const SearchResult = ({data = []}) => {
    if (!data.length) {
        return <EmptySearchResult />
    }

    return data.map((row, idx) => (
        <Link to={`/papers/${row.id}`} key={row.id}>
            <ListItem
                // endEnhancer={() => <ChevronRight color="contentInversePrimary" size={16} overrides={{Svg: {style: ({$theme}) => ({backgroundColor: $theme.colors.backgroundInversePrimary, borderRadius: $theme.borders.radius300})}}} />}
                sublist
                overrides={{
                    Root: {style: ({$theme}) => ({
                            cursor: 'pointer',
                            // backgroundColor: idx === focusedIndex ? $theme.colors.backgroundSecondary : $theme.colors.backgroundPrimary,
                            ':hover': {backgroundColor: $theme.colors.backgroundSecondary},
                    })},
                    Content: {style: {paddingTop: '12px', paddingBottom: '12px'}},
                }}
            >
                <ListItemLabel overrides={{LabelContent: {style: {fontSize: '14px'}}}}>{row.title}</ListItemLabel>
            </ListItem>
        </Link>
    ))
}

function EmptySearchResult () {
    return (
        <FlexBlock width='100%' height="150px" alignItems="center" justifyContent="center">
            <LabelSmall color="contentTertiary">검색 결과가 없습니다</LabelSmall>
        </FlexBlock>
    )
}
const Search = ({q, onChange}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const debouncedQ = useDebounce(q, 200);
    const {isLoading, data = []} = useSearch(debouncedQ);

    const ref = React.useRef();

    function handleOpen () {
        setIsOpen(true)
    }
    function handleClose () {
        setIsOpen(false)
    }
    const handleKeyup = e => {
        if (!e.target.classList.contains('editor')){
            const slashEntered = e.key === '/';
            const onDocument = e.target.tagName !== 'INPUT';
            if (slashEntered && onDocument) {
                ref.current?.focus();
            }
        }
    }

    React.useEffect(() => {
        document.addEventListener('keyup', handleKeyup);
        return () => {
            document.removeEventListener('keyup', handleKeyup);
        }
    }, [])
    return (
        <Popover
            content={<SearchResult data={data} />}
            autoFocus={false}
            placement={PLACEMENT.topRight}
            isOpen={isOpen}
            onEsc={handleClose}
            onClickOutside={handleClose}
            onClick={handleOpen}
            overrides={{
                Body: {
                    style: ({$theme}) => ({
                        width: '240px',
                        backgroundColor: $theme.colors.backgroundPrimary,
                        borderTopLeftRadius: $theme.borders.radius300,
                        borderTopRightRadius: $theme.borders.radius300,
                        borderBottomRightRadius: $theme.borders.radius300,
                        borderBottomLeftRadius: $theme.borders.radius300,
                    }),
                },
                Inner: {
                    style: ({$theme}) => ({
                        width: '100%',
                        backgroundColor: $theme.colors.backgroundPrimary,
                        borderTopLeftRadius: $theme.borders.radius300,
                        borderTopRightRadius: $theme.borders.radius300,
                        borderBottomRightRadius: $theme.borders.radius300,
                        borderBottomLeftRadius: $theme.borders.radius300,
                        overflow: 'hidden',
                    })
                }
            }}
        >
            <InputWrapper>
                <Input
                    value={q}
                    inputRef={ref}
                    placeholder="검색어를 입력해 주세요"
                    clearOnEscape
                    onChange={e => {
                        if (!q) {
                            handleClose();
                        } else if (!isOpen) {
                            handleOpen();
                        }
                        onChange(e);
                    }}
                    startEnhancer={<SearchIcon size="18px" />}
                    endEnhancer={() => {
                        if (isLoading) {
                            return <Spinner $size="20px" $width='1px' $color="#000" />
                        }
                        // return <ShortCutBlock>/</ShortCutBlock>
                        return <></>
                    }}
                    size={SIZE.compact}
                    overrides={{
                        Root: {
                            style: ({$theme}) => ({
                                marginLeft: 'auto',
                                borderTopLeftRadius: $theme.borders.radius300,
                                borderTopRightRadius: $theme.borders.radius300,
                                borderBottomRightRadius: $theme.borders.radius300,
                                borderBottomLeftRadius: $theme.borders.radius300,
                                paddingRight: '6px',
                                paddingLeft: 0,
                                paddingRight: 0
                            })
                        },
                        EndEnhancer: {
                            style: ({$theme}) => ({
                                paddingRight: '0',
                                display: 'none',
                                [$theme.mediaQuery.medium]: {
                                    display: 'flex',
                                }
                            }),
                        }
                        //
                    }}
                />
            </InputWrapper>
        </Popover>
    )
}

export default Search;