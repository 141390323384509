import React, { useEffect } from 'react'
import { useAuth } from './auth'
import { Routes, useLocation, useNavigate } from 'react-router-dom'
import { createBrowserHistory } from 'history'

const routes = Object.freeze({
	projects: '/',
	dashboard: '/dashboard',
	projectDetail: '/papers/:id',
	emailTemplates: '/email-templates',
	emailTemplatesCreate: '/email-templates/new',
	emailTemplatesDetail: '/email-templates/:id',
	clients: '/clients',
	accounts: '/accounts',
	signin: '/accounts/signin',
})

export const PrivateRoutes = ({ children, location }) => {
	let loc = useLocation()
	const auth = useAuth()
	const navigate = useNavigate()

	function redirect() {
		navigate(routes.signin, { state: { from: loc } })
	}

	const verifyToken = () => {
		if (loc.pathname === routes.signin) return

		if (auth.isAuthenticated) {
			auth.verify()
				.then((result) => {
					if (!result) {
						redirect()
					}
				})
				.catch((err) => {
					if (err.response?.status === 401) {
						auth.refresh()
					} else {
						redirect()
					}
				})
		} else {
			redirect()
		}
	}

	useEffect(() => {
		verifyToken()
	}, [loc])

	return <Routes children={children} location={location} />
}

export const history = createBrowserHistory()

export default routes
