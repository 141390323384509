import React from "react";

import FlexBlock from "./FlexBlock";
import {Block} from "baseui/block";
import ClipboardText from "./ClipboardText";
import {LinkIcon} from "@heroicons/react/outline";
import {useStyletron} from "baseui";


export default function DataGrid ({content, clipboardEnabled, linkEnabled, ...restProps}) {
    const [css] = useStyletron();
    return (
        <FlexBlock flex='1 1 auto' marginLeft='10px' justifyContent='space-between' flexWrap='wrap' {...restProps}>
            {content.map((row, index) => (
                <React.Fragment>
                    <FlexBlock marginBottom='10px' flexDirection='column' key={index}>
                        <Block>{row.label}</Block>
                        <Block>
                            {clipboardEnabled ? <ClipboardText>{row.content}</ClipboardText> : row.content}
                            {linkEnabled && <LinkIcon width='15px' className={css({marginLeft: '10px', verticalAlign:'middle', cursor: 'pointer'})} onClick={void 0} />}
                        </Block>
                    </FlexBlock>
                    {Boolean(index % 2) && (<FlexBlock />)}
                </React.Fragment>
            ))}

        </FlexBlock>
    )
}