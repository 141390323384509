import axios from "axios";
import { authStorage, sinceFromNow } from "./utils";
import qs from "qs";

export function request({ method, url, params, headers, data, ...options }) {
    const accessToken = authStorage.getAccessToken();
    const baseOptions = {
        baseURL: process.env.REACT_APP_GROUPWARE_API_URL,
        method: method,
        url: url,
        timeout: 10000,
        widthCredentials: true,
        maxRetries: 0,
        maxRedirects: 1,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            ...headers,
        },
        data: data,
        params: { ...params },
        paramsSerializer: (q) => {
            return qs.stringify(q, { indices: false });
        },
        ...options,
    };
    return axios(baseOptions);
}

export function listPapers({ queryKey }) {
    const [, params] = queryKey;
    return request({ url: `/papers`, params }).then((res) => res.data);
}

export function searchPapers({ queryKey }) {
    const [, q] = queryKey;
    return request({ url: "/papers/search", params: { q } }).then((res) => res.data);
}

export function createPaper(payload) {
    return request({ method: "POST", url: "/papers/project", data: payload });
}
export function createPaperEtc(payload) {
    return request({ method: "POST", url: "/papers/etc", data: payload });
}

export function detailPaper({ queryKey }) {
    const [, { id }] = queryKey; // eslint-disable-line
    return request({ url: `/papers/${id}` }).then((res) => res.data);
}

export function updatePaper(payload) {
    const { id, ...data } = payload;
    return request({
        url: `/papers/${id}`,
        method: "PATCH",
        data,
    }).then((res) => res.data);
}

export function deletePaper({ id }) {
    return request({
        url: `/papers/${id}`,
        method: "DELETE",
    }).then((res) => res.data);
}
export function listNotes({ queryKey }) {
    const [, { paperId }] = queryKey; // eslint-disable-line
    const params = { paper: paperId };
    return request({ url: `/notes`, params }).then((res) => res.data);
}

export function createNote(payload) {
    return request({ method: "POST", url: `/notes`, data: payload });
}

export function updateNote(payload) {
    const { id, ...rest } = payload;
    return request({ method: "PATCH", url: `/notes/${id}`, data: rest });
}

export function deleteNote(id) {
    return request({ method: "DELETE", url: `/notes/${id}` });
}

export function listNotifications({ pageParam = 1 }) {
    return request({ url: "/notifications", params: { page: pageParam } }).then((res) => {
        const { results, ...rest } = res.data;
        rest.results = results.map((row) => ({
            content: row.message,
            is_read: row.is_read,
            url: row.url,
        }));
        return rest;
    });
}

export function markAsReadNotifications() {
    return request({ url: "/notifications/mark", method: "POST" }).then((res) => res.data);
}

export function loginApi(payload) {
    return request({
        method: "POST",
        url: "/accounts/token",
        data: payload,
        maxRetries: 0,
        maxRedirects: 1,
    }).then((res) => res.data);
}

export function logoutApi() {
    return request({
        method: "POST",
        url: `/accounts/logout`,
        maxRetries: 0,
        maxRedirects: 1,
    }).then((res) => res.data);
}

export function loadUser() {
    return request({ url: "/accounts/my-profile" }).then((res) => res.data);
}

export function verifyToken() {
    return request({
        method: "POST",
        url: "/accounts/token/verify",
        data: { token: authStorage.getAccessToken() },
    });
}

export function refreshToken() {
    return request({
        method: "POST",
        url: "/accounts/token/refresh",
        data: { refresh: authStorage.getRefreshToken() },
    });
}
export function listClient({ pageParam = 1 }) {
    return request({ url: "/clients", params: { page: pageParam } }).then((res) => res.data);
}

export function getClient({ queryKey }) {
    const [, { id }] = queryKey;
    return request({ url: `/clients/${id}` }).then((res) => res.data);
}

export function listAssignee({ queryKey }) {
    const [, { clientId, paperId }] = queryKey;
    return request({
        url: "/assignees",
        params: { company: clientId, paper: paperId },
    }).then((res) => res.data);
}

export function updatePaperStatus(payload) {
    const { id, ...data } = payload;
    return request({ url: `/papers/${id}/status`, method: "PATCH", data }).then((res) => res.data);
}

export function listFiles({ queryKey }) {
    const [, { paper }] = queryKey;
    return request({
        url: "/attachments",
        params: { paper, is_note_image: false },
    }).then((res) => res.data);
}

export function uploadFile(payload) {
    return request({
        url: "/attachments",
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data; boundary=Uee--r1_eDOWu7FpA0LJdLwCMLJQapQGu",
        },
        data: payload,
    }).then((res) => res.data);
}

export function listPaperLogs({ queryKey }) {
    const [, { id }] = queryKey;
    return request({
        url: `/papers/${id}/logs`,
    }).then((res) => res.data);
}

export function listEmailTemplates() {
    return request({ url: "/email-templates" }).then((res) => res.data.map((row) => ({ ...row, label: row.name, updated: sinceFromNow(res.updated_datetime) })));
}

export function detailEmailTemplate({ queryKey }) {
    const [, { id }] = queryKey;
    return request({
        url: `/email-templates/${id}`,
    }).then((res) => res.data);
}

export function updateEmailTemplate(payload) {
    const { id, ...data } = payload;

    return request({
        url: `/email-templates/${id}`,
        method: "PATCH",
        data: data,
    }).then((res) => res.data);
}

export function deleteEmailTemplate(id) {
    return request({
        url: `/email-templates/${id}`,
        method: "DELETE",
    });
}
export function createEmailTemplate(payload) {
    return request({
        url: `/email-templates`,
        method: "POST",
        data: payload,
    }).then((res) => res.data);
}

export function sendEmail(payload) {
    const baseUrl = "/email-templates";
    return request({
        url: payload.id ? baseUrl + `/${payload.id}` : baseUrl,
        method: payload.id ? "PATCH" : "POST",
        data: payload,
    }).then((res) => res.data);
}

export function getProjectsDATA() {
    const baseUrl = `${process.env.REACT_APP_GROUPWARE_API_URL}/papers/statistics`;
    return request({
        baseURL: baseUrl,
        method: "GET",
    }).then((res) => res.data);
}
