import {Link as BaseLink} from "react-router-dom";
import {useStyletron} from "baseui";

export default function Link (props) {
    const [css, theme] = useStyletron();
    return (
        <BaseLink
            className={css({
                textDecoration: 'none',
                color: theme.colors.contentPrimary,
                ':hover': {
                    color: theme.colors.accent500
                }
            })}
            {...props}
        />
    )
}