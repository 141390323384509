import { useStyletron } from 'baseui'
import { StatefulTooltip } from 'baseui/tooltip'
import { PLACEMENT } from 'baseui/popover'

export function Root({ children, ...props }) {
	const [css, theme] = useStyletron()
	const { $style = {}, to, active, disabled, ...rest } = props
	let defaultStyle = {
		display: 'flex',
		padding: '0 2rem',
		backgroundColor: active ? theme.colors.backgroundSecondary : 'transparent',
		borderTopRightRadius: '30px',
		borderBottomRightRadius: '30px',
		color: active ? theme.colors.contentPrimary : disabled ? theme.colors.contentStateDisabled : '#949494',
		height: theme.sizing.scale1000,
		marginTop: '0',
		marginRight: '2rem',
		cursor: 'default',
		pointerEvents: disabled ? 'none' : 'all',
	}
	defaultStyle =
		!disabled && to
			? {
					...defaultStyle,
					...{
						cursor: 'pointer',
						':hover': { backgroundColor: theme.colors.backgroundSecondary },
					},
			  }
			: defaultStyle
	defaultStyle = { ...defaultStyle, ...$style }
	return disabled ? (
		<StatefulTooltip content={'오픈 예정입니다.'} returnFocus autoFocus placement={PLACEMENT.left} overrides={{ Body: { style: { zIndex: '1000000' } } }}>
			<div className={css(defaultStyle)} {...rest}>
				{children}
			</div>
		</StatefulTooltip>
	) : (
		<div className={css(defaultStyle)} {...rest}>
			{children}
		</div>
	)
}

export function IconContainer({ children, ...props }) {
	const [css] = useStyletron()
	const { $style = {}, ...restProps } = props
	return (
		<div
			className={css({
				display: 'flex',
				alignItems: 'center',
				...$style,
			})}
			{...restProps}
		>
			{children}
		</div>
	)
}

export function Item({ children, ...props }) {
	const [css] = useStyletron()
	const { $style = {}, ...restProps } = props
	return (
		<div
			className={css({
				display: 'flex',
				alignItems: 'center',
				marginLeft: '2rem',
				...$style,
			})}
			{...restProps}
		>
			{children}
		</div>
	)
}
