import React, { useState } from 'react'
import { styled, withStyle } from 'baseui'
import { CountBox } from './CountBox'

const VALUE_INFOS = [
	{
		name: 'new',
		label: '의뢰 접수 ',
	},
	{
		name: 'aligning',
		label: '협의 진행 ',
	},
	{
		name: 'in_progress',
		label: '착수 ',
	},
]

export default function DetailPropjectCount(props) {
	const { total, detail } = props

	return (
		<Flex>
			<CountBox title={'총 의뢰 건수'} count={total} />
			{VALUE_INFOS.map((v, i) => (
				<CountBox delay={0.15 * (i + 1)} title={v.label} key={`${i}`} count={detail[v.name]} style={{
					// backgroundColor:`rgba(75, 202, 129, 1)`,
					// color:'#fff'
				}} />
			))}
		</Flex>
	)
}

const Flex = styled('div', ({ $theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	gap: '1rem',
	'@media screen and (max-width:768px)': {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
	},
}))
