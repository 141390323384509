import React from "react";
import {SIZE, ROLE, Modal, ModalHeader, ModalBody, ModalFooter, ModalButton} from "baseui/modal";
import {KIND as ButtonKind} from "baseui/button";

export default function Confirm ({isOpen = false, onConfirm, header, body, onClose = void 1}) {
    return (
        <Modal
            onClose={onClose}
            closeable
            isOpen={isOpen}
            animate
            autoFocus
            size={SIZE.default}
            role={ROLE.dialog}
            overrides={{Root: {style: ({$theme}) => ({zIndex: $theme.zIndex.modal})}}}
        >
            <ModalHeader>{header || '프로젝트 종료'}</ModalHeader>
            <ModalBody>{body || "진행하시겠습니까?"}</ModalBody>
            <ModalFooter>
                <ModalButton kind={ButtonKind.tertiary} onClick={onClose} 
                    overrides={{ BaseButton: { style: { borderRadius: '7px', overflow: 'hidden', maxHeight: '40px', backgroundColor: '#fff !important', border: '1px solid #b7b6b6', color: '#000'} }}} children={'취소'} />
                <ModalButton kind={ButtonKind.tertiary} onClick={onConfirm} overrides={{ BaseButton: { style: { borderRadius: '7px', overflow: 'hidden', maxHeight: '40px', backgroundColor: '#017a5a !important', color: '#fff'} }}} children={'확인'} />
            </ModalFooter>
        </Modal>
    );
}