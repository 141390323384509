import React, { useEffect, useRef } from 'react'
import { useStyletron } from 'baseui'
import { useLocation, useNavigate } from 'react-router-dom'
import { BookOpenIcon, DocumentReportIcon, LogoutIcon, ChartBarIcon } from '@heroicons/react/outline'
import Logo from '../Logo'
import SidebarItem from './SidebarItem'
import BaseSidebar from 'react-sidebar'
import { LabelXSmall } from 'baseui/typography'
import Settings from './Settings'
import routes from '../../routes'
import { useAuth } from '../../auth'

const SidebarItemList = (props) => {
	const { loc } = props
	const auth = useAuth()
	const [, theme] = useStyletron()
	const settingRef = useRef()
	const navigate = useNavigate()

	function handleClickSetting() {
		settingRef.current.setIsOpen(true)
	}

	return (
		<React.Fragment>
			<SidebarItem
				children={<Logo />}
				onClick={() => {
					if (window.location.href.includes('?page')) {
						window.location.replace(new URL(window.location.href).origin);
					} else {
						navigate(routes.projects)
					}
				}}
				overrides={{
					Root: { style: { cursor: 'pointer', transform: 'translateY(-10%)', marginBottom: '3rem', padding: '0 2rem 0', ':hover': {} } },
					Item: { style: { marginLeft: '0rem' } },
				}}
			/>
			{/* <SidebarItem children={<LabelXSmall color={theme.colors.contentSecondary}>Menu</LabelXSmall>} overrides={{ Item: { style: { marginLeft: '0' } } }} /> */}
			{/*<SidebarItem*/}
			{/*    Icon={ViewGridIcon}*/}
			{/*    active={loc.pathname === routes.dashboard}*/}
			{/*    to={routes.dashboard}*/}
			{/*    children="대시보드"*/}
			{/*/>*/}
			<div className='sidebar-tabs'>
				<SidebarItem active={loc.pathname === routes.projects || loc.pathname.startsWith('/papers')} to={routes.projects} children="프로젝트" />
				<SidebarItem active={loc.pathname.startsWith(routes.emailTemplates)} to={routes.emailTemplates} children="템플릿 관리" />
				<SidebarItem active={loc.pathname.startsWith(routes.dashboard)} to={routes.dashboard} children="데이터" />
			</div>
			{/*<SidebarItem*/}
			{/*    Icon={OfficeBuildingIcon}*/}
			{/*    active={loc.pathname.startsWith(routes.clients)}*/}
			{/*    to={routes.clients}*/}
			{/*    children="클라이언트 관리"*/}
			{/*    disabled*/}
			{/*/>*/}
			{/*<SidebarItem*/}
			{/*    Icon={UserIcon}*/}
			{/*    active={loc.pathname.startsWith(routes.accounts)}*/}
			{/*    to={routes.accounts}*/}
			{/*    children="계정 관리"*/}
			{/*    disabled*/}
			{/*/>*/}
			{/* <SidebarItem
				children={<LabelXSmall color={theme.colors.contentSecondary}>Settings</LabelXSmall>}
				overrides={{
					Root: { style: { marginTop: 'auto' } },
					Item: { style: { marginLeft: '0' } },
				}}
			/> */}
			{/* <SidebarItem*/}
			{/*    Icon={<CogIcon width={16} />}*/}
			{/*    children="설정"*/}
			{/*    overrides={{*/}
			{/*        Root: {style: {cursor: 'pointer'}},*/}
			{/*        Item: {style: {...theme.typography.font200}}*/}
			{/*    }}*/}
			{/*    disabled*/}
			{/*    onClick={handleClickSetting}*/}
			{/*/> */}
			<SidebarItem
				children="Logout"
				overrides={{
					Root: { style: { marginTop: 'auto', marginBottom: '20px', cursor: 'pointer' } },
					Item: { style: { ...theme.typography.font200, marginLeft: 0 } },
				}}
				onClick={() => {
					auth.logout()
					navigate(routes.projects)
				}}
			/>
			<Settings ref={settingRef} />
		</React.Fragment>
	)
}

export default function Sidebar(props) {
	const { sidebarOpen, setSidebarOpen, sidebarDocked, children, ...restProps } = props
	const [css, theme] = useStyletron() // eslint-disable-line
	const loc = useLocation()

	return (
		<BaseSidebar
			defaultSidebarWidth={240}
			sidebar={<SidebarItemList loc={loc} />}
			open={sidebarOpen}
			onSetOpen={setSidebarOpen}
			docked={sidebarDocked}
			shadow={false}
			styles={{
				sidebar: {
					backgroundColor: theme.colors.backgroundPrimary,
					padding: '2rem 0 1rem' ,
					display: 'flex',
					flexDirection: 'column',
					marginTop: '0',
					width: '260px',
					
				},
			}}
			{...restProps}
		>
			{children}
		</BaseSidebar>
	)
}
