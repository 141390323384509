import React from 'react'
import { useStyletron } from 'baseui'
import { Menu } from 'baseui/icon'
import Notification from './Notification'
import Search from './Search'

export default function Header({ handleClickBurger }) {
	const [css, theme] = useStyletron()
	const [q, setQ] = React.useState('')

	const handleSearchChange = React.useCallback(
		(e) => {
			setQ(e.target.value)
		},
		[q]
	)

	return (
		<div
			id="header"
			className={css({
				display: 'flex',
				padding: '.5rem 1rem',
				justifyContent: 'space-between',
				alignItems: 'center',
			})}
		>
			<Menu
				size={30}
				color={theme.colors.contentPrimary}
				overrides={{
					Svg: { style: { cursor: 'pointer' } },
				}}
				onClick={handleClickBurger}
			/>
			<div
				className={css({
					display: 'flex',
					width: '175px',
					[theme.mediaQuery.medium]: { width: '400px' },
				})}
			>
				
			</div>
			{/* <Notification /> */}
		</div>
	)
}
