import { Button, KIND } from 'baseui/button'
import { SIZE } from 'baseui/input'
import { BellIcon } from '@heroicons/react/outline'
import { useStyletron } from 'baseui'
import React, { forwardRef, useEffect, useState } from 'react'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import NotificationList from './NotificationList'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { listNotifications, markAsReadNotifications } from '../../core/apis'
import qs from 'qs'

const Notification = forwardRef((props, ref) => {
    const [css, theme] = useStyletron();
    const {isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, isIdle, data = [], isError} = useInfiniteQuery('notifications', listNotifications, {
        getNextPageParam: ((lastPage) => {
            if (lastPage.next) {
                const { page } = qs.parse(lastPage.next.split('?')[1]);
                return parseInt(page);
            }
            return false;
        }),
        keepPreviousData: true,
        select: data => data.pages.flatMap(page => page.results),
    });
    const queryClient = useQueryClient();
    const unread = data.some(row => !row.is_read);

	const mutation = useMutation(markAsReadNotifications, {
		onSuccess: () => {
			queryClient.invalidateQueries('notifications').then()
		},
	})

    return (
        <div className={css({
            display: 'flex',
            position: 'relative',
            background: 'transparent',
            "::after": {
                content: "''",
                position: 'absolute',
                width: '7px',
                height: '7px',
                borderRadius: '50%',
                backgroundColor: theme.colors.contentNegative,
                right: '7px',
                top: '7px',
                transitionProperty: 'transform',
                transitionDuration: theme.animation.timing300,
                transitionTimingFunction: theme.animation.easeInOutQuinticCurve,
                transform: unread ? 'scale(1)' : 'scale(0)',
            }
        })}>
            <StatefulPopover
                content={({close}) => (
                    <NotificationList close={close} isLoading={isFetching} isError={isError} content={data} hasNextPage={hasNextPage} isFetchingNextPage={isFetchingNextPage} fetchNextPage={fetchNextPage} isIdle={isIdle}  />
                )}
                returnFocus
                autoFocus
                onOpen={() => mutation.mutate()}
                placement={PLACEMENT.topLeft}
                overrides={{
                    Body: { style: { marginRight: '3.8rem', boxShadow: 'unset', ...theme.borders.border100 } },
                    Inner: { style: { backgroundColor: theme.colors.backgroundPrimary } }
                }}
            >
                <Button
                    kind={KIND.tertiary}
                    size={SIZE.mini}
                    ref={ref}
                    {...props}
                >
                    <BellIcon className={css({ stroke: '#fff', width: '28px', pointerEvents:'none' })} />
                </Button>
            </StatefulPopover>
        </div>
    )
})

export default Notification
