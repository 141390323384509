import React from "react";
import {Modal, ModalBody, ModalHeader, ROLE, SIZE} from "baseui/modal";
import {Skeleton} from "baseui/skeleton";
import FlexBlock from "../FlexBlock";
import {Block} from "baseui/block";
import {useStyletron} from "baseui";
import {Tab, Tabs} from "baseui/tabs";
import {CheckCircleIcon, LinkIcon, MinusCircleIcon} from "@heroicons/react/outline";
import {TriangleDown} from "baseui/icon";
import {Avatar} from "baseui/avatar";
import {ListItem, ListItemLabel} from "baseui/list";
import {Tag, VARIANT} from "baseui/tag";
import Link from "../Link";
import {Table} from "baseui/table-semantic";
import ClipboardText from "../ClipboardText";
import DataGrid from "../DataGrid";


const data = [
    {
        title: '나이스홀딩스 웹사이트 UX/UI/개발',
        overdue: '2022-02-21',
        participants: <>
            <Avatar name='U X' size="scale900" overrides={{Root: {style: {border: '1px solid #fff', marginLeft: '-7px', position: 'relative', zIndex: '3'}}}} />
            <Avatar name='U I' size="scale900" overrides={{Root: {style: {border: '1px solid #fff', marginLeft: '-7px', position: 'relative', zIndex: '2'}}}} />
            <Avatar name='D X' size="scale900" overrides={{Root: {style: {border: '1px solid #fff', marginLeft: '-7px', position: 'relative', zIndex: '1'}}}} />
        </>,
        status: '진행',
        id: 1,
        info: [
            {
                label: '이메일',
                content: 'example@gmail.com',
            },
            {
                label: '웹사이트',
                content: 'https://naver.com',
            }
        ]
    },
    {
        title: '걸스플래닛999 공식 웹사이트 UX/UI/개발',
        overdue: '2021-10-30',
        participants: ['UX', 'UI', 'DX'],
        status: '종료',
        id: 2
    }
];



const ClientDetail = React.forwardRef((props, ref) => {
    const [css, theme] = useStyletron();
    const [isOpen, setIsOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [activeKey, setActiveKey] = React.useState("0");

    React.useImperativeHandle(ref, () => ({
        reset () {
            setLoading(true);
            // TODO: data reset
        },
        setIsOpen,
    }))

    React.useEffect(() => {
        isOpen && setTimeout(() => {
            setLoading(false)
        }, 500);
    }, [isOpen]);


    return (
        <Modal
            onClose={() => setIsOpen(false)}
            closeable
            isOpen={isOpen}
            animate
            autoFocus
            size={SIZE.full}
            role={ROLE.alertdialog}
            overrides={{
                Root: { style: {zIndex: 20} },
                Dialog: { style: {maxWidth: '1000px'}}
            }}
            ref={ref}
        >
            <ModalHeader>
                {loading ? <Skeleton height="25px" width="50%" animation={true}/> : <Block marginLeft='15px'>크래프톤</Block>}
            </ModalHeader>
            <ModalBody>
                {loading ? <Skeleton rows={3} width="70%" animation={true} /> :
                    <FlexBlock flex='1' flexWrap='wrap'>

                            <Tabs
                                onChange={({ activeKey }) => {
                                    setActiveKey(activeKey);
                                }}
                                activeKey={activeKey}
                                overrides={{
                                    Root: {style: {flex: '1', maxWidth: '100%'}},
                                    TabBar: {style: {backgroundColor: 'transparent'}},
                                    TabContent: {style: {marginTop: '15px'}}
                                }}

                            >
                                <Tab title="기본정보">
                                    {/*START INFO*/}
                                    <DataGrid content={data[0].info} />
                                    {/*END INFO*/}

                                    {/*START TREAD*/}
                                    {/*<MainThreadViewer initialValue="Hello, World" />*/}
                                    {/*END THREAD*/}

                                    {/*TODO: 추가기능 예정*/}
                                    {/*<FlexBlock flexDirection='column' marginTop='2rem'>*/}
                                    {/*    <ThreadViewer*/}
                                    {/*        avatar={*/}
                                    {/*            <Avatar*/}
                                    {/*                name="Jane Doe"*/}
                                    {/*                size="scale1000"*/}
                                    {/*                src="https://avatars.dicebear.com/api/human/yard.svg?width=285&mood=happy"*/}
                                    {/*            />*/}
                                    {/*        }*/}
                                    {/*        author="홍길동"*/}
                                    {/*        lastEdited="2021년 1월 20일 오후 11:22"*/}
                                    {/*        initialValue="Hello, World2"*/}
                                    {/*        overrides={{ ViewerContainer: { style: { marginLeft: '12px' } } }}*/}
                                    {/*    />*/}

                                    {/*    <ThreadEditor*/}
                                    {/*        avatar={*/}
                                    {/*            <Avatar*/}
                                    {/*                name="Jane Doe"*/}
                                    {/*                size="scale1000"*/}
                                    {/*                src="https://avatars.dicebear.com/api/human/yard.svg?width=285&mood=happy"*/}
                                    {/*            />*/}
                                    {/*        }*/}
                                    {/*        initialValue="Hello, World"*/}
                                    {/*        overrides={{Root: {style: {marginTop: '5rem'}}}}*/}
                                    {/*    />*/}
                                    {/*</FlexBlock>*/}
                                </Tab>
                                <Tab title="프로젝트">

                                    <Block overflow="hidden" className={css({borderRadius: theme.borders.radius300, ...theme.borders.border200})}>
                                        <FlexBlock backgroundColor={theme.colors.backgroundSecondary} padding='0.7rem' height='33px' alignItems='center'>
                                            <FlexBlock className={css(theme.typography.font200)}>
                                                <CheckCircleIcon width='18px' className={css({margin: '0 .5rem'})} />4 Open
                                            </FlexBlock>
                                            <FlexBlock className={css(theme.typography.font200)} color={theme.colors.mono700}>
                                                <MinusCircleIcon width='18px' className={css({margin: '0 .5rem'})} />20 Closed
                                            </FlexBlock>
                                            <FlexBlock className={css({marginLeft: 'auto', ...theme.typography.font200, alignItems: 'center'})} color={theme.colors.mono700}>
                                                정렬 <TriangleDown size={18} />
                                            </FlexBlock>
                                            <FlexBlock className={css({marginLeft: '30px', marginRight: '10px', ...theme.typography.font200})} color={theme.colors.mono700}>
                                                필터 <TriangleDown size={18} />
                                            </FlexBlock>
                                        </FlexBlock>

                                        {data.map((row, idx) => (
                                            <ListItem
                                                endEnhancer={() => (
                                                    <Block>
                                                        <Avatar name='U X' size="scale900" overrides={{Root: {style: {border: '1px solid #fff', position: 'relative', zIndex: '3'}}}} />
                                                        <Avatar name='U I' size="scale900" overrides={{Root: {style: {border: '1px solid #fff', marginLeft: '-7px', position: 'relative', zIndex: '2'}}}} />
                                                        <Avatar name='D X' size="scale900" overrides={{Root: {style: {border: '1px solid #fff', marginLeft: '-7px', position: 'relative', zIndex: '1'}}}} />
                                                    </Block>
                                                )}
                                                artwork={() => <Tag closeable={false} variant={VARIANT.solid}>{row.status}</Tag>}
                                                key={idx}
                                                overrides={{
                                                    Root: { style: { borderTopStyle: theme.borders.border200.borderStyle, borderTopColor: theme.borders.border200.borderColor, borderTopWidth: theme.borders.border200.borderWidth } },
                                                    ArtworkContainer: { style: { marginLeft: '.5rem' } },
                                                    EndEnhancerContainer: { style: { marginRight: '.5rem' } },
                                                    Content: { style: { marginLeft: '.3rem', borderBottomStyle: 'none' } }
                                                }}
                                            >
                                                <ListItemLabel
                                                    overrides={{
                                                        LabelContent: {
                                                            style: theme.typography.LabelMedium
                                                        },
                                                        LabelDescription: {
                                                            style: {
                                                                color: theme.colors.contentTertiary,
                                                                ...theme.typography.font100,
                                                            }
                                                        }
                                                    }}
                                                    description={`#${row.id} 3일 전에 열림`}
                                                >
                                                    <Link to={`/papers/${row.id}`}>{row.title}</Link>
                                                </ListItemLabel>
                                            </ListItem>
                                        ))}

                                    </Block>

                                </Tab>
                                <Tab title="담당자">
                                    <Table
                                        columns={["이름", "부서", "직급", "연락처", "이메일"]}
                                        data={[
                                            [
                                                "홍길동",
                                                "마케팅팀",
                                                "과장",
                                                <>
                                                    <ClipboardText>010-0000-0000</ClipboardText>
                                                    {/*<DuplicateIcon width='15px' className={css({marginLeft: '10px', verticalAlign:'middle', cursor: 'pointer'})} />*/}
                                                    <LinkIcon width='15px' className={css({marginLeft: '10px', verticalAlign:'middle', cursor: 'pointer'})} />
                                                </>,
                                                <>
                                                    <ClipboardText>example@gmail.com</ClipboardText>
                                                    {/*<DuplicateIcon width='15px' className={css({marginLeft: '10px', verticalAlign:'middle', cursor: 'pointer'})} />*/}
                                                    <LinkIcon width='15px' className={css({marginLeft: '10px', verticalAlign:'middle', cursor: 'pointer'})} />
                                                </>

                                            ],
                                            [
                                                "김영희",
                                                "영업팀",
                                                "대리",
                                                <>
                                                    <ClipboardText>010-1234-1234</ClipboardText>
                                                    {/*<DuplicateIcon width='15px' className={css({marginLeft: '10px', verticalAlign:'middle', cursor: 'pointer'})} />*/}
                                                    <LinkIcon width='15px' className={css({marginLeft: '10px', verticalAlign:'middle', cursor: 'pointer'})} />
                                                </>,
                                                <>
                                                    <ClipboardText>sales@gmail.com</ClipboardText>
                                                    {/*<DuplicateIcon width='15px' className={css({marginLeft: '10px', verticalAlign:'middle', cursor: 'pointer'})} />*/}
                                                    <LinkIcon width='15px' className={css({marginLeft: '10px', verticalAlign:'middle', cursor: 'pointer'})} />
                                                </>

                                            ]
                                        ]}
                                    />
                                </Tab>
                            </Tabs>
                        </FlexBlock>
                }

            </ModalBody>
            {/*<ModalFooter>*/}
            {/*    {loading ? <Skeleton height="25px" width="100%" animation={true} /> : (*/}
            {/*        <React.Fragment>*/}
            {/*        <ModalButton kind={ButtonKind.tertiary}> Cancel</ModalButton>*/}
            {/*        <ModalButton>Okay</ModalButton>*/}
            {/*        </React.Fragment>*/}
            {/*    ) }*/}
            {/*</ModalFooter>*/}
        </Modal>
    )
})
export default ClientDetail;