import React from 'react'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { loadUser, loginApi, refreshToken, verifyToken } from './core/apis'
import { authStorage } from './core/utils'

export const AuthContext = React.createContext({
    profile: {
        id: null,
        name: null,
        username: null,
        profile_image: null,
        email: null,
    },
    isAuthenticated: !!authStorage.getAccessToken(),
    isLoggingIn: false,
    isVerifying: false,
    refetchUser: () => {},
    login: () => {},
    logout: () => {},
    verify: () => {},
    refresh: () => {},
})

export const AuthProvider = (props) => {
    const queryClient = useQueryClient()
    const key = 'auth'
    let isAuthenticated = !!authStorage.getAccessToken()
    const signinPage = window.location.pathname === '/accounts/signin'
    const [isLoggingIn, setLoggingIn] = React.useState(false)
    const [isVerifying, setIsVerifying] = React.useState(false)

    const { data: user, refetch } = useQuery({
        queryKey: key,
        queryFn: loadUser,
        enabled: !signinPage,
    })

    async function loginFunc(payload) {
        const tokens = await loginApi(payload)
        authStorage.setTokens(tokens)
        return await loadUser()
    }

    async function verifyFunc() {
        const axiosResponse = await verifyToken()
        return axiosResponse.status === 200;
    }

    async function refreshFunc() {
        const axiosResponse = await refreshToken()
        authStorage.setUpdateAccessToken(axiosResponse.data)
    }

    const setUser = React.useCallback((data) => queryClient.setQueryData(key, data), [queryClient])

    const loginMutation = useMutation({
        mutationFn: loginFunc,
        onMutate: () => setLoggingIn(true),
        onSuccess: (user) => {
            setUser(user)
        },
        onSettled: () => setLoggingIn(false),
    })

    const logoutMutation = useMutation({
        mutationFn: () => {
            authStorage.clearTokens()
        },
        onSuccess: () => {
            queryClient.clear()
        },
    })

    const verifyMutation = useMutation({
        mutationFn: verifyFunc,
        onMutate: () => setIsVerifying(true),
        onError: () => {
            queryClient.clear()
            isAuthenticated = false
        },
        onSettled: () => setIsVerifying(false),
    })

    const refreshMutation = useMutation({
        mutationFn: refreshFunc,
        onSuccess: () => {isAuthenticated = true},
        onError: () => {
            authStorage.clearTokens()
            queryClient.clear()
            isAuthenticated = false
        },
    })
    const initialState = {
        profile: user,
        login: loginMutation.mutateAsync,
        logout: logoutMutation.mutateAsync,
        verify: verifyMutation.mutateAsync,
        refresh: refreshMutation.mutateAsync,
        isLoggingIn,
        isVerifying,
        refetchUser: refetch,
        isAuthenticated: isAuthenticated,
    }

    return <AuthContext.Provider value={initialState} {...props} />
}

export const useAuth = () => {
    return React.useContext(AuthContext)
}
