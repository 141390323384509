import React, { useState,useContext } from 'react'
import spacetime from "spacetime";
import ReactDOM from 'react-dom'
import { useNavigate, useParams } from 'react-router-dom'
import { createTheme, useStyletron } from 'baseui'
import ContentHeader from '../../components/ContentHeader'
import { HeadingXSmall, LabelMedium, LabelSmall } from 'baseui/typography'
import { Tab, Tabs } from 'baseui/tabs'
import { Button, KIND, SHAPE, SIZE as ButtonSize } from 'baseui/button'
import { SIZE } from 'baseui/tag'
import { Block } from 'baseui/block'
import FlexBlock from '../../components/FlexBlock'
import ClientDetail from '../../components/Clients/Detail'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createNote, deleteNote, deletePaper, detailPaper, listNotes, updateNote, updatePaper, getClient } from '../../core/apis'
import Layout from '../../components/Layout'
import { TriangleDown } from 'baseui/icon'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import WeakButton from '../../components/WeakButton'
import BorderedButton from '../../components/BorderedButton'
import { StatefulMenu } from 'baseui/menu'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import { sinceFromNow, statusToStep } from '../../core/utils'
import routes from '../../routes'
import { useSnackbar } from 'baseui/snackbar'
import { Spinner } from 'baseui/spinner'
import { useAuth } from '../../auth'
import StatusTag from '../../components/StatusTag'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import { MilkdownEditor } from '../../components/MilkdownEditor'
import Timeline from '../../components/Projects/Timeline'
import RequestBody from '../../components/Projects/RequestBody'
import ProjectFileTab from '../../components/Projects/FileTab'
import LogList from '../../components/Projects/LogsList'
import { Input, SIZE as InputSize } from 'baseui/input'
import { Skeleton } from 'baseui/skeleton'
import { DotsVerticalIcon } from '@heroicons/react/outline'

const Header = ({ title, documentId, status, clientName, createdTime }) => {
	const [css, theme] = useStyletron()
	const [isOpen, setIsOpen] = React.useState(false)
	const [changing, setChanging] = React.useState(false)
	const [_clientName, setClientName] = React.useState(clientName);
	const [time, setTime] = React.useState(createdTime)
	const [value, setValue] = React.useState('')
	const [isLoading, setIsLoading] = React.useState(false)
	const navigate = useNavigate()

	const queryClient = useQueryClient()

	const mutation = useMutation(updatePaper, {
		onMutate: () => setIsLoading(true),
		onSuccess: () => {
			queryClient.invalidateQueries(['paperDetail', { id: parseInt(documentId) }])
		},
		onSettled: () => {
			setIsLoading(false)
			setChanging(false)
		},
	})

	const deleteMutation = useMutation(deletePaper, {
		onMutate: () => setIsLoading(true),
		onSuccess: () => {
			queryClient.invalidateQueries('papers')
			navigate(routes.projects)
		},
		onSettled: () => {
			setIsLoading(false)
			setChanging(false)
		},
	})

	React.useEffect(() => {
		setValue(title)
	}, [title])

	const handleClick = () => setIsOpen(true)

	const handleClose = () => setIsOpen(false)

	function handleSubmit() {
		mutation.mutate({ id: documentId, title: value })
	}

	function handleDelete() {
		deleteMutation.mutate({ id: documentId })
	}

	function handleCancel() {
		setChanging(false)
	}


	return (
		<Block padding="0 10px" marginTop="1rem" marginBottom=".5rem" paddingLeft="8px" paddingRight="8px">
			<FlexBlock alignItems="center" justifyContent="space-between" className={changing && 'is-changing'}>
				{changing ? (
					<>
						<Input size={InputSize.compact} overrides={{ Root: { style: { width: '80%' } } }} value={value} onChange={(e) => setValue(e.target.value)} />
						<Block>
							<Button size={ButtonSize.compact} overrides={{ Root: { style: { marginRight: '10px' } } }} isLoading={isLoading} onClick={handleSubmit}>
								저장
							</Button>
							<Button size={ButtonSize.compact} kind={KIND.secondary} onClick={handleCancel}>
								취소
							</Button>
						</Block>
					</>
				) : (
					<>
					<Block style={{ position: 'relative', width: '100%'}}>
					<ContentHeader
							title={'프로젝트'}
							style={{ border: 'none !important', marginTop: '-1rem', marginBottom: '10px', paddingTop: '57px', paddingBottom: 'calc(10px + 12px)',  }}
							actions={
								<FlexBlock
									className={css({
										// cursor: 'pointer',
										...theme.typography.font200,
										"@media only screen and (max-width: 450px)": {
											display: "none",
										},
									})}
									color={isOpen ? theme.colors.mono1000 : theme.colors.mono700}
									// onClick={() => (handleOpenFilter(true))}
								>
								</FlexBlock>
							}
						/>
						<Block style={{position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: 0 }} id="heading-container">
						
						<HeadingXSmall width="calc(100% - 100px)" marginBottom="0 !important" marginLeft="0 !important" paddingRight="0" style={{width: '100%', paddingRight: 0}}>
							<FlexBlock flexDirection="row" justifyContent="space-between" style={{width: '100%',}}>
							{title && 
							<FlexBlock alignItems="center" id="title-header" alignItems="center" style={{ gap: '10px'}}>
								<StatusTag status={status} size={SIZE.medium} style={{ maxHeight: '24px !important', padding: '2px 8px !important', width: '45px'}} />
								{title}&nbsp;&nbsp;#{documentId}
							</FlexBlock>
							}
							<span id="button-edit" className={css({ color: theme.colors.contentNegative })} onClick={() => handleClick()}>삭제</span>
							</FlexBlock>
						</HeadingXSmall>
						</Block>
					</Block>
					</>
				)}
				{/*</HeadingXSmall>*/}
			</FlexBlock>
			<FlexBlock alignItems="flex-start" flexDirection="column-reverse">
				<FlexBlock flexDirection="row" alignItems="center" style={{gap: '10px'}}>
				{/* <StatusTag status={'rejected'} size={SIZE.medium} style={{ maxHeight: '24px !important', padding: '2px 8px !important', width: '45px'}} /> */}
				</FlexBlock>
				{/*<Paragraph3 marginLeft='5px'>프로젝트 기간: 2021. 05. 01 ~ 2021. 09. 30</Paragraph3>*/}
				<FlexBlock id="client-row" flexDirection="row" alignItems="center" justifyContent="space-between">
					<div className='client-name'>{clientName}</div>
					<div className='created-time'>{spacetime(time).unixFmt('YYYY.MM.dd HH:mm')}</div>
				</FlexBlock>
			</FlexBlock>
			<Modal
				id="modal-confirm"
				isOpen={isOpen}
				onClose={handleClose}
				overrides={{
					Root: { style: { zIndex: 20 } },
				}}
			>
				<FlexBlock flexDirection="column">
					<ModalHeader style={{fontSize: '24px'}}>프로젝트 삭제</ModalHeader>
					<ModalBody>
						<b className='title'>{title} #{documentId}</b> <p>정말 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.</p>
					</ModalBody>
				</FlexBlock>
				<ModalFooter className={css({ marginBottom: '.7rem' })}>
					<FlexBlock id="flex-buttons">
						<WeakButton overrides={{ BaseButton: { style: { width: '100%', } } }} isLoading={isLoading} onClick={handleDelete}>
							삭제
						</WeakButton>
						<WeakButton overrides={{ BaseButton: { style: { width: '100%',  } } }} isLoading={isLoading} onClick={handleClose}>
							취소
						</WeakButton>
					</FlexBlock>
				</ModalFooter>
			</Modal>
		</Block>
	)
}

const TabContentWrapper = (props) => {
	return <Block display="flex" paddingTop="40px" paddingBottom="48px" width="100%" flex="1 0 auto" height="100%" {...props} />
}

// const ProjectDetailForm = (props) => {
//     const formRef = React.useRef();
//
//     return (
//         <React.Fragment>
//             <ProjectRequestForm
//                 ref={formRef}
//                 {...props}
//                 project_categories={[{label: '웹', value: 'web'}, {label: '앱', value: 'app'}]}
//                 domain={[{label: '컨설팅', value: 'consulting'},
//                     {label: '신규 구축', value: 'building'},]}
//                 budget={[{label: '5천만 원 미만', value: 25}]}
//             />
//             <Button overrides={{BaseButton: {style: {float: 'right'}}}}>수정</Button>
//         </React.Fragment>
//     )
// }

const NoteBox = ({ editorKey, paperId, label, editable, onSubmit, hasPerm, ...editorProps }) => {
	const [css, theme] = useStyletron()
	const [editableState, setEditable] = React.useState(editable)

	const queryClient = useQueryClient()

	const mutation = useMutation(deleteNote, {
		onSuccess: () => {
			queryClient.invalidateQueries(['noteList', { paperId }])
			// setIsOpen(false);
		},
	})

	return (
		<FlexBlock
			className={css({
				flexDirection: 'column',
				[theme.mediaQuery.medium]: {
					flexDirection: 'row',
				},
			})}
		>
			<Block
				className={css({
					width: '100%',
					[theme.mediaQuery.medium]: {
						width: '10%',
					},
				})}
			>
				<LabelSmall
					className={css({
						paddingTop: '1rem',
						paddingLeft: '0.5rem',
						paddingBottom: '0.5rem',
						display: 'flex',
						[theme.mediaQuery.medium]: {
							display: 'block',
						},
					})}
				>
					{label}
				</LabelSmall>
			</Block>
			<Block
				className={css({
					width: '100%',
					position: 'relative',
					[theme.mediaQuery.medium]: {
						width: '90%',
					},
				})}
			>
				<MilkdownEditor
					editorKey={editorKey}
					editable={editableState}
					onCancel={() => {
						setEditable(false)
					}}
					onSubmit={(content) => {
						onSubmit(content)
						setEditable(false)
					}}
					{...editorProps}
				/>
				{hasPerm && (
					<Block position="absolute" id="button-comment-edit" top="calc(1rem - 3px)"right="0" margin="auto" height="28px" style={{  }}>
						<StatefulPopover
							content={({ close }) => (
								<StatefulMenu
									items={[
										{
											label: <span className={css({ color: theme.colors.contentNegative })}>삭제</span>,
											onClick: () => {

												if (window.confirm('삭제하시겠습니까?')) {
													mutation.mutate(editorKey)
												}
												
											},
										},
									]}
									onItemSelect={({ item }) => {
										item.onClick()
										close()
									}}
								/>
							)}
							placement={PLACEMENT.bottomLeft}
							overrides={{
								Body: {
									style: {
										boxShadow: 'unset',
										...theme.borders.border100,
										width: '120px',
										'@media screen and (max-width:768px)': {
											width: '60px'
										},
									},
								},
								Inner: {
									style: {
										backgroundColor: theme.colors.backgroundPrimary,
									},
								},
							}}
						>
							<Button kind={KIND.tertiary} shape={SHAPE.circle} size={ButtonSize.mini}>
								<DotsVerticalIcon width="15px" />
							</Button>
						</StatefulPopover>
					</Block>
				)}
			</Block>
		</FlexBlock>
	)
}

const NoteBoxContainer = ({ paperId }) => {
	paperId = parseInt(paperId)
	const { profile } = useAuth()
	const [css, theme] = useStyletron()
	const { data, isLoading, isError } = useQuery(['noteList', { paperId }], listNotes, { refetchOnMount: false })
	const queryClient = useQueryClient()

	const mutation = useMutation(updateNote, {
		onSuccess: () => {
			queryClient.invalidateQueries(['noteList', { paperId }], { exact: true })
			// setIsOpen(false);
		},
	})

	if (isLoading) {
		return <Skeleton height="125px" width="100%" animation />
	}

	if (isError) {
		return <LabelSmall color="contentNegative">컨텐츠를 가져오는데 실패했습니다</LabelSmall>
	}

	return data.map((row) => (
		<Block key={row.id} className="comment" margin="0.5rem 0" padding="0">
			<NoteBox
				editorKey={row.id}
				paperId={paperId}
				label={
					<>
						<Block className='comment-name' paddingBottom='0'>{row.author.name}</Block>
						<Block
							className={css({
								fontSize: '10px',
								color: theme.colors.contentSecondary,
								paddingLeft: '.5rem',
								[theme.mediaQuery.medium]: {
									paddingLeft: '0',
								},
							})}
						>
							{sinceFromNow(row.created_datetime)}
						</Block>
					</>
				}
				editable={false}
				initialContent={row.content}
				hasPerm={profile?.id === row.author.id}
				onSubmit={(content) => {
					mutation.mutate({ id: row.id, content })
				}}
			/>
		</Block>
	))
}

export default function ProjectDetail() {
	const [activeKey, setActiveKey] = React.useState('0')
	const [, theme] = useStyletron()
	const modalRef = React.useRef(null)
	const navigate = useNavigate()
	const { enqueue } = useSnackbar()
	const { id } = useParams()
	const queryClient = useQueryClient()

	const { data: paper, isLoading, isError } = useQuery(['paperDetail', { id: parseInt(id) }], detailPaper, { refetchOnMount: false })
	const [loadAttachCount, setLoadAttactCount] = useState(false)

	if (isError) {
		enqueue(
			{
				message: '잘못된 URL 접근입니다.',
				overrides: {
					Content: {
					  props: {
						className: 'error-message', 
					  },
					  style: ({ $theme }) => ({
						backgroundColor: '#cd1b18', 
						borderRadius: '5px',
						overflow: 'hidden',
						color: '#fff',
					  }),
					},
				},
			},
			3000
		)
		navigate(routes.projects)
	}

	const mutation = useMutation(createNote, {
		onSuccess: () => {
			queryClient.invalidateQueries(['noteList', { paperId: parseInt(id) }], { exact: true })
		},
	})

	const handleSubmitNote = (content) => {
		const payload = { paper: id, content: content }
		mutation.mutate(payload)
	}

	const tabContainerOptions = {
		Root: { style: { height: '100%' } },
		Tab: { style: { minWidth: '65px' } },
		TabContent: {
			style: {
				height: '100%',
				display: 'flex',
				flex: '1 0 auto',
				maxWidth: '1200px',
				paddingTop: '0',
				paddingBottom: '0',
			},
		},
	}

	React.useEffect(() => {
		if (paper && paper.attachment_count) {
			if (loadAttachCount) return
			setLoadAttactCount(true)
			const attachmentTab = document.querySelector('[role="tab"][id="2"]')
			const badge = React.createElement('span', { className: 'attachment-badge', children: paper.attachment_count })

			ReactDOM.render(['첨부파일', badge], attachmentTab)
		}
	}, [paper])

	// const { client: clientId, group, project_categories: projectCategories, domain, request_type: requestType, request_description, description, budget, client_ip } = paper;
	// const { data: client, isSuccess: clientFetched } = useQuery(["clientDetail", { id: clientId }], getClient, { enabled: !!clientId, refetchOnMount: false });
	
	return (
		<>
			<Layout>
				<FlexBlock flexDirection="column" maxWidth="1200px">
					<Header title={paper?.title} documentId={paper?.id} status={paper?.state} clientName={paper?.client_name} createdTime={paper?.created_datetime} />
					<Tabs
						activeKey={activeKey}
						onChange={({ activeKey }) => {
							setActiveKey(activeKey)
						}}
						overrides={tabContainerOptions}
						activateOnFocus
					>
						<Tab title="상태관리">
							{isLoading ? (
								<FlexBlock justifyContent="center" alignItems="center" width="100%" height="500px">
									<Spinner $size="50px" $borderWidth="5px" $color="#000" />
								</FlexBlock>
							) : (
								<TabContentWrapper>
									<FlexBlock height="100%" flexDirection="column" width="765px" maxWidth="100vw" marginRight="1rem" padding="0 0 1rem" style={{gap: '40px'}}>
										{/*<LabelSmall paddingLeft='1.25em'>{profile.name}</LabelSmall>*/}
								
										<Timeline id={id} initial={statusToStep(paper?.state)} status={paper?.state} />
										<Block>
											<MilkdownEditor editorKey="main" id={id} onSubmit={handleSubmitNote} clearAfterSubmit={true} autofocus={true} />
										</Block>
										<Block id={'comment-list'}>
											<NoteBoxContainer paperId={id} />
										</Block>
									</FlexBlock>
								</TabContentWrapper>
							)}
						</Tab>
						<Tab title="의뢰내용">
							<TabContentWrapper paddingTop={0}>
								<RequestBody paper={paper} />
							</TabContentWrapper>
						</Tab>
						<Tab title="첨부파일">
							<TabContentWrapper flexWrap="wrap">
								{/*<FlexBlock overflow="hidden">*/}
								<ProjectFileTab paperId={id} />
								{/*</FlexBlock>*/}
							</TabContentWrapper>
						</Tab>
						<Tab title="시스템 로그">
							<TabContentWrapper>
								<LogList id={id} />
							</TabContentWrapper>
						</Tab>
					</Tabs>
					<ClientDetail ref={modalRef} />
				</FlexBlock>
			</Layout>
		</>
	)
}