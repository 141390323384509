import {useStyletron} from "baseui";
import React, { useState } from "react";
import {
    defaultValueCtx,
    Editor,
    editorViewCtx,
    editorViewOptionsCtx,
    rootCtx,
    serializerCtx
} from "@milkdown/core";
import {forceUpdate, replaceAll, insert} from "@milkdown/utils";
import {ReactEditor, useEditor} from "@milkdown/react";
import {nordLight} from "@milkdown/theme-nord";
import {commonmark} from "@milkdown/preset-commonmark";
import {history} from "@milkdown/plugin-history";
import {clipboard} from "@milkdown/plugin-clipboard";
import {emoji} from "@milkdown/plugin-emoji";
import FlexBlock from "./FlexBlock";
import {Block} from "baseui/block";
import {Button, KIND, SIZE as ButtonSize} from "baseui/button";
import {PhotographIcon} from "@heroicons/react/outline";
import {useMutation} from "react-query";
import {uploadFile} from "../core/apis";

let editableState = {};

function getEditable(editorKey) {
    return () => typeof editableState[editorKey] === 'undefined' ? true : editableState[editorKey];
}

function useMilkdownEditor (editorKey, initialContent) {
    return useEditor((root) =>
        Editor.make()
            .config((ctx) => {
                ctx.set(rootCtx, root);
                ctx.set(defaultValueCtx, initialContent);
                ctx.set(editorViewOptionsCtx, {editable: getEditable(editorKey)})
            })
            .use(nordLight)
            .use(commonmark)
            .use(history)
            .use(clipboard)
            .use(emoji)
    );
}
function replaceLinksWithFileNames(content) {
    return content.replace(/<([^|]+)\|([^>]+)>/g, (match, url, title) => {
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
            url = `https://${url}`;
        }
        return `[${title}](${url})`;
    });
}

export const MilkdownEditor = ({editorKey, id, initialContent, editable, onSubmit, onCancel, clearAfterSubmit, autofocus}) => {
    const [css] = useStyletron();
    const ref = React.useRef();
    const uploadMutation = useMutation(uploadFile, {
        onSuccess: (data) => {
            const instance = getEditorInstance();
            instance.action(insert(`<br class="hide" /><${data.file}|${data.title.split(' ').join('_')}>`))
        }
    })
    // const [editableState, setEditable] = React.useState(editable);
    editableState[editorKey] = editable;
    // const [orgValue, setOrgValue] = React.useState(initialContent)

    if (typeof initialContent === 'string') {
        initialContent = replaceLinksWithFileNames(initialContent);
    } else {
        initialContent = '';
    }
    const editor = useMilkdownEditor(editorKey, initialContent);

    function getEditorInstance () {
        return ref.current?.get();
    }

    function getMarkdown() {
        const editor = ref.current?.get();
        if (!editor) return '';
        return editor.action((ctx) => {
            const editorView = ctx.get(editorViewCtx);
            const serializer = ctx.get(serializerCtx);
            return serializer(editorView.state.doc);
        })
    }

    function clearContent() {
        const editor = ref.current?.get();
        if (!editor) return '';
        editor.action(replaceAll("", true));
    }

    function handleCancel () {
        const editor = ref.current?.get();
        if (!editor) return '';
        editor.action(replaceAll(initialContent, true));
        
    }

    function focus (editor) {
        if (editor) {
            // setEditable(true)
            if (getEditable(editorKey)()) {
                const domm = ref.current?.dom().querySelector('.milkdown .editor');
                domm?.focus();
            }
        }
    }

    React.useEffect(() => {
        if (autofocus) {
            focus(getEditorInstance());
        }
    }, [ref.current])

    React.useEffect(() => {
        const editor = getEditorInstance();
        if (editor) {
            // setEditable(true)
            editor.action(forceUpdate());
            focus(editor);
        }
    }, [editable])


    React.useEffect(() => {
        if (ref?.current && ref.current.dom()) {

            const _dom = ref.current.dom();
            const _paragraph = _dom.querySelectorAll('.paragraph');
            [..._paragraph].forEach(paragraph => {
                [...paragraph.querySelectorAll('a')].forEach(link => {
                    const _href = link.getAttribute('href');
                    if (!_href.includes('https://')) {
                        link.setAttribute('href', `https://${_href}`);
                    }
                    if (!link.target) {
                        link.setAttribute("target", "_blank"); // target 속성 추가
                    }
                })
            })
        }
    }, [ref.current]); 

    const [files, setFiles] = React.useState([])
    return (
        <>
            <ReactEditor editor={editor} ref={ref} tabIndex='-1'/>
            {getEditable(editorKey)() && (
                <FlexBlock justifyContent="center" marginLeft="auto" marginTop='0.5rem' style={{width: 'max-content', marginLeft: 'auto'}} id="milkdown-editor">
                    <Block style={{ display: 'flex', alignItems: 'center'}}>
                        <Block>
                            <label htmlFor="blah" accept={".png, .jpg, .jpeg, .pdf, .doc, .docx"} multiple={false} className="input-file-label">파일 첨부</label>
                        </Block>
                        <input type="file" id="blah"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                const formData = new FormData();

                                formData.append('file', file);
                                formData.append('paper', id);
                                formData.append('is_note_image', true);
                                uploadMutation.mutate(formData)
                                setFiles((_file) => [..._file, file.name])
                            }}/>
                    </Block>
                        {onCancel && (
                            <Button
                                size={ButtonSize.compact}
                                kind={KIND.secondary}
                                onClick={() => {
                                    handleCancel();
                                    onCancel();
                                }}
                                overrides={{ Root: { style: {marginRight: '.5rem'}} }}
                            >취소</Button>
                        )}
                        <Button
                            size={ButtonSize.compact}
                            id="button-add-comment"
                            onClick={() => {
                                onSubmit && onSubmit(getMarkdown());
                                if (clearAfterSubmit) {
                                    clearContent();
                                }
                            }}
                            $style={{
                                borderRadius:'3px'
                            }}
                        >등록</Button>
                </FlexBlock>
            )}
            {files.length > 0 && <FlexBlock className='file-badge-container'>{files.map(f => <span className="file-badge">{f}</span>)}</FlexBlock>}
        </>

    )
}